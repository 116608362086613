import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import HardHatIcon from '@/components/Common/Icons/HardHatIcon.vue'
import MachineIcon from '@/components/Common/Icons/MachineIcon.vue'
import TaskPadIcon from '@/components/Common/Icons/TaskPadIcon.vue'
import ShieldIcon from '@/components/Common/Icons/ShieldIcon.vue'
import HammerWrenchIcon from '@/components/Common/Icons/HammerWrenchIcon.vue'
import ArrowsSplitIcon from '@/components/Common/Icons/ArrowsSplitIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'AboutStatisticBox',
  components: {
    ArrowLeftCarousel,
    HardHatIcon,
    TaskPadIcon,
    MachineIcon,
    ShieldIcon,
    HammerWrenchIcon,
    ArrowsSplitIcon,
    ButtonMain,

  },
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },

  }
}