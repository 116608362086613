<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'pagination': true,
    'pagination-size-small': size === 'small',
    'pagination-size-medium': size === 'medium',
    'pagination-size-large': size === 'large',
  }">
    <div class="circle" @click="prev()">
      <div href="#" @click="prev()">&lt;</div>
    </div>
    <div
        v-for="(num, index) in pages"
         v-bind:class="{'circle': true, 'active': parseInt(page + 1) === parseInt(num)}"
         :key="'pp' + index"
         @click="selectPage(index);
    ">
      <div>{{ num }}</div>
    </div>
<!--    <div class="circle no-border"><a href="#">...</a></div>-->
    <div class="circle" @click="next()">
      <div href="#">&gt;</div>
    </div>
  </div>
</template>

