import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import OneJobListComponent from "@/components/Components/OneJobListComponent/OneJobListComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import Parallaxy from '@lucien144/vue3-parallaxy';
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import PinIcon from '@/components/Common/Icons/PinIcon.vue'
import BlueHeading from "@/components/Components/BlueHeading/BlueHeading.vue";
import PaginationComponent from "@/components/Components/PaginationComponent/PaginationComponent.vue";

export default {
  name: "OneCategoryPage",
  components: {
    IndexCarousel,
    HighlightIndustries,
    HowDoesItWork,
    WhoWeAre,
    ReviewsComponent,
    OneJobListComponent,
    ContactForm,
    MessageIcon,
    PhoneIcon,
    PinIcon,
    Parallaxy,
    BlueHeading,
    PaginationComponent,

  },
  data() {
    return {
      oneJobList: null,
      category: null,
      offset: 0,
      limit: 10,
      pages: null
    }
  },
  async mounted() {
    const categorySlug = this.$route.params.slug;
    this.category = await this.$store.dispatch("jobs/getCategoryBySlug", categorySlug);
    await this.fetchJobs();
  },
  methods: {
    async handlePageChange(page) {
      page = parseInt(page);
      this.offset = page * this.limit;
      await this.fetchJobs();
    },
    async fetchJobs() {
      const categorySlug = this.$route.params.slug;
      const result = await this.$store.dispatch("jobs/getJobsByCategory", {
        slug: categorySlug,
        offset: this.offset,
        limit: this.limit
      });

      this.oneJobList = result.data;
      this.total = result.total;
      this.pages = Math.ceil(this.total / this.limit);
    }
  }
}
