export default {
	name: 'InputForm',
	props: {
		input: {
			type: String,
		},
		content: {
			type: String,
		},
		size: {
			type: String,
			default: 'medium',
		},
		theme: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		inputtype: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		handleInput(e) {
			this.$emit('update:modelValue', e.target.value);
		},
	},
};
