
<template>
  <div v-bind:class="{


  }">
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.01361 0C10.4179 0.0222613 13.3182 2.51552 13.9026 5.94376C14.1437 7.3388 13.9172 8.6819 13.4424 9.98789C12.8579 11.5907 11.9886 13.0377 11.017 14.4179C9.90656 15.991 8.65733 17.4454 7.31313 18.8108C7.06475 19.0631 6.95516 19.0631 6.70678 18.8108C4.85851 16.926 3.18556 14.9002 1.83406 12.6147C1.09621 11.3607 0.467944 10.0547 0.168421 8.61511C-0.306432 6.31478 0.226864 4.25932 1.71717 2.47842C2.82029 1.15759 4.24485 0.385862 5.91779 0.103886C6.29037 0.0445226 6.65564 0.0371021 7.01361 0ZM10.2207 7.15329C10.2207 5.35013 8.78883 3.87346 7.02822 3.87346C5.23839 3.87346 3.80652 5.32045 3.79922 7.13103C3.79191 8.91935 5.23839 10.3886 7.0063 10.396C8.75961 10.396 10.2134 8.93419 10.2207 7.15329Z" fill="#00bf3d"/>
    </svg>




  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>