
<template>
  <div v-bind:class="{


  }">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM16 31L45 31L45 29L16 29L16 31Z" fill="white"/>
      <rect x="59" y="59" width="58" height="58" rx="29" transform="rotate(-180 59 59)" stroke="white" stroke-width="2"/>
    </svg>




  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>