<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
  }">
    <label class="container">
      <input  type="checkbox" v-model="isChecked" @change="emitCheckboxChange">
      <div class="checkmark"></div>
    </label>


  </div>
</template>

