
export default {
    name: 'Pagination',
    props: {
        size: {
            type: String,
            default: 'small'
        },
        pages: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        },
        route: {
            type: String,
            default: ''
        },
        routeParams: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            page: 1
        }
    },
    methods: {
        selectPage(number) {
            this.page = number;
            this.$emit('pageChanged', number)
        },
        next() {
            if (this.page >= this.$props.pages - 1) {
                return;
            }
            this.page++;
            this.$emit('pageChanged', this.page)
        },
        prev() {
            if (this.page === 0) {
                return;
            }
            this.page--;
            this.$emit('pageChanged', this.page)
        }
    },
    mounted() {
        this.page = this.$props.current;
    }
}