<script src="./IndustryBox.js"></script>
<style src="./IndustryBox.scss" lang="scss" scoped/>
<template>
  <div class="industry-box-wrapper">
      <div class="blue-icon">
        <RepairIcon :theme="'blue'" v-if="icon === 'repair'"/>
        <WaterSewageIcon :theme="'blue'" v-if="icon === 'watersewage'"/>
        <FactoryIcon :theme="'blue'" v-if="icon === 'factory'"/>
        <Manufacture :theme="'blue'" v-if="icon === 'manufacture'"/>
      </div>
      <div class=" white-icon">
        <RepairIcon :theme="'white'" v-if="icon === 'repair'"/>
        <FactoryIcon :theme="'white'" v-if="icon === 'factory'"/>
        <WaterSewageIcon :theme="'white'" v-if="icon === 'watersewage'"/>
        <Manufacture :theme="'white'" v-if="icon === 'manufacture'"/>
      </div>

    <div class="industry-box-title"> {{ title }}</div>
    <div class="industry-box-button"> View all job offers</div>
  </div>

</template>
