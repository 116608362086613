<script src="./StepProgressBar.js"></script>
<style src="./StepProgressBar.scss" lang="scss" scoped/>
<template>

  <div class="stepper-wrapper d-none d-md-flex">
    <div class="stepper-item completed">
      <div class="step-number">01</div>
      <div class="step-counter"></div>
      <div class="step-name">
        <div>Find the sphere that</div>
        <div>you want to work</div>
      </div>

    </div>
    <div class="stepper-item completed">
      <div class="step-number">02</div>
      <div class="step-counter"></div>
      <div class="step-name">
        <div>View all job offers</div>
        <div>in this industry</div>
      </div>

    </div>
    <div class="stepper-item completed">
      <div class="step-number">03</div>
      <div class="step-counter"></div>
      <div class="step-name">
        <div>Apply for a job simply</div>
        <div>by sending your CV</div>
      </div>

    </div>

  </div>
  <div class="wrapper d-md-none">
    <ul class="StepProgress">
      <li class="StepProgress-item is-done">
        <div class="step-number">01</div>
        <div class="step-name">
          <div>Find the sphere that</div>
          <div>you want to work</div>
        </div>

      </li>
      <li class="StepProgress-item is-done">
        <div class="step-number">02</div>
        <div class="step-name">
          <div>View all job offers</div>
          <div>in this industry</div>
        </div>

      </li>
      <li class="StepProgress-item is-done">
        <div class="step-number">03</div>
        <div class="step-name">
          <div>Apply for a job simply</div>
          <div>by sending your CV</div>
        </div>

      </li>

    </ul>
  </div>

</template>
