
<template>
  <div v-bind:class="{


  }">
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.86295 1.93443C4.86295 1.61749 4.86295 1.3224 4.86295 1.02732C4.86295 0.398907 5.29119 0 5.97168 0C6.9924 0 8.01313 0 9.03386 0C9.70848 0 10.1367 0.398907 10.1367 1.03279C10.1367 1.32787 10.1367 1.62295 10.1367 1.93443C10.1954 1.93443 10.2482 1.93443 10.301 1.93443C11.087 1.93443 11.8731 1.93443 12.6592 1.93443C13.9732 1.93443 14.994 2.88525 14.9998 4.10929C14.9998 4.45902 14.9764 4.80874 15.0057 5.15847C15.0468 5.6776 14.5951 6.12568 14.1023 6.23497C12.9701 6.48634 11.8438 6.74863 10.7116 7.00546C10.1954 7.12022 9.68501 7.24044 9.14532 7.36066C9.14532 7.19126 9.13945 7.03825 9.14532 6.89071C9.17465 6.33333 8.74641 5.85792 8.09526 5.84699C7.64942 5.84153 7.20945 5.84153 6.76362 5.86339C6.28845 5.88525 5.87782 6.28415 5.86022 6.72678C5.84848 6.92896 5.86022 7.13661 5.86022 7.36066C5.54931 7.28962 5.25599 7.22404 4.96268 7.15847C3.61344 6.85246 2.27007 6.54645 0.926701 6.24044C0.463267 6.13661 0.158222 5.86885 0.0232987 5.43716C0.0174324 5.40984 0.00569992 5.38251 0.00569992 5.36066C0.0115662 4.8306 -0.0118988 4.29508 0.0350312 3.77049C0.117159 2.88525 1.00296 2.07104 1.95329 1.96721C2.11168 1.95082 2.27594 1.93989 2.43433 1.93989C3.18521 1.93443 3.93609 1.93989 4.68697 1.93989C4.73976 1.93443 4.79256 1.93443 4.86295 1.93443ZM5.74876 1.92896C6.92201 1.92896 8.07766 1.92896 9.25091 1.92896C9.25091 1.62295 9.25091 1.3224 9.25091 1.02732C9.25091 0.857923 9.20984 0.819672 9.02799 0.819672C8.39444 0.819672 7.76088 0.819672 7.12733 0.819672C6.73429 0.819672 6.33538 0.819672 5.94234 0.819672C5.84262 0.819672 5.75462 0.846994 5.75462 0.945355C5.74289 1.27322 5.74876 1.59563 5.74876 1.92896Z" fill="#00bf3d"/>
      <path d="M14.9938 6.78687C14.9938 7.50818 14.9938 8.23496 14.9938 8.96174C14.9938 9.3825 14.9938 9.80873 14.9938 10.2295C14.9879 11.2131 14.1608 12 13.1049 12C9.36807 12 5.63127 12 1.89447 12C0.844411 12 0.0114032 11.2186 0.00553691 10.2404C-0.00032934 9.12021 0.00553691 7.99452 0.00553691 6.8743C0.00553691 6.84152 0.00553691 6.81419 0.00553691 6.76501C0.128728 6.82512 0.234321 6.8907 0.351646 6.92348C0.867876 7.0601 1.38997 7.19124 1.91207 7.31146C3.19678 7.60654 4.48149 7.89616 5.76619 8.18578C5.84832 8.20217 5.88939 8.24042 5.92458 8.31693C6.0947 8.68851 6.39388 8.90709 6.83385 8.91802C7.27969 8.92895 7.71966 8.92895 8.16549 8.91802C8.60546 8.90709 8.90464 8.68305 9.07476 8.31693C9.10996 8.24042 9.15689 8.20764 9.23901 8.18578C10.9285 7.80327 12.6238 7.42075 14.3133 7.03277C14.5362 6.97813 14.7533 6.8743 14.9938 6.78687Z" fill="#00bf3d"/>
      <path d="M8.26547 7.3825C8.26547 7.56283 8.26547 7.74862 8.26547 7.92895C8.26547 8.04917 8.21267 8.10381 8.08361 8.10381C7.69057 8.10381 7.3034 8.10381 6.91036 8.10381C6.78717 8.10381 6.73438 8.04917 6.73438 7.93988C6.73438 7.57376 6.73438 7.20217 6.73438 6.83605C6.73438 6.72676 6.79304 6.67212 6.9045 6.67212C7.3034 6.67212 7.70231 6.67212 8.10708 6.67212C8.21854 6.67212 8.27133 6.73223 8.27133 6.83605C8.2596 7.02185 8.26547 7.20217 8.26547 7.3825Z" fill="#00bf3d"/>
    </svg>





  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>