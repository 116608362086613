import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './styles/main.scss';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import VuePrlx from 'vue-prlx';
import { VueReCaptcha } from 'vue-recaptcha-v3'

createApp(App)
    .use(store)
    .use(router)
    .use(VuePrlx)
    .use(VueReCaptcha, {
        siteKey: '6Le3pjUpAAAAAM7aXcSXFv8nvAYUormZRv_H1jel',
        loaderOptions: {
            autoHideBadge: true
        }
    })
    .mount("#app");
