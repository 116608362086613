
<template>
  <div v-bind:class="{


  }">
    <div v-if="color === 'green'">
      <svg width="51" height="37" viewBox="0 0 51 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 17.5L18 32.5L47.5 3" stroke="#8BCF35" stroke-width="6" stroke-linecap="round"/>
      </svg>

    </div>
    <div v-if="color === 'blue'">
      <svg width="51" height="37" viewBox="0 0 51 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 17.5L18 32.5L47.5 3" stroke="#5E8BFF" stroke-width="6" stroke-linecap="round"/>
      </svg>

    </div>





  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: '',
    }

  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>