<script src="./OneJobPage.js"></script>
<style src="./OneJobPage.scss" lang="scss" scoped/>
<template>
  <div v-if="oneJob">
    <div class="header-wrapper" ref="headerWrapper">
      <BlueHeading :title="oneJob.title"/>
    </div>
    <div class="container one-category-wrapper">
      <div class="row container">
        <div class="job-description-wrapper col-md-8">
          <div class="d-md-flex">
            <div class="description-title d-none d-md-block">Job description</div>
<!--            <div class="ms-auto job-views">views: {{ oneJob.views }} </div>-->
          </div>
          <div class="one-job-component col-md-4 d-md-none" ref="oneJobComponent">
            <OneJobApplyComponent
                :location="oneJob.location"
                :job_type="oneJob.job_type"
                :job_hours="oneJob.job_hours"
                :currency="oneJob.currency"
                :salary="oneJob.salary"
            />
          </div>
          <div class="description-title d-md-none">Job description</div>
          <div class="job-description" v-html="oneJob.job_description"></div>
        </div>
        <div class="one-job-component col-md-4 d-none d-md-block" ref="oneJobComponent">
          <OneJobApplyComponent
              :location="oneJob.location"
              :job_type="oneJob.job_type"
              :job_hours="oneJob.job_hours"
              :currency="oneJob.currency"
              :salary="oneJob.salary"
          />
        </div>
      </div>
    </div>
  </div>
</template>
