
<template>
  <div v-bind:class="{


  }">
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.50666 0C13.1956 0 17 3.82583 17 8.52994C17 13.1941 13.169 17.0067 8.48006 17C3.78444 17 -0.00664228 13.1675 8.73796e-06 8.43014C0.00665976 3.80587 3.8443 0 8.50666 0ZM7.795 6.54716C7.795 7.28571 7.80165 8.03092 7.78835 8.76947C7.78835 9.02896 7.87481 9.23523 8.06104 9.42153C8.93232 10.2798 9.79031 11.1515 10.6549 12.0164C10.9476 12.3092 11.2402 12.6086 11.5329 12.8947C11.7923 13.1476 12.1381 13.1808 12.4241 12.9879C12.8165 12.7284 12.8564 12.2227 12.5039 11.8701C11.4398 10.7988 10.3689 9.73425 9.30478 8.66301C9.24492 8.60313 9.19837 8.49667 9.19837 8.41018C9.19172 7.88454 9.19171 7.3589 9.19171 6.83327C9.19171 5.98826 9.19171 5.14325 9.19171 4.29158C9.19171 4.05871 9.11855 3.85245 8.93898 3.70607C8.71284 3.51977 8.45345 3.49315 8.19406 3.61292C7.92802 3.73268 7.78835 3.95225 7.78835 4.24501C7.795 5.01018 7.795 5.782 7.795 6.54716Z" fill="#00bf3d"/>
    </svg>







  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>