import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'SingleReview',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,

  },
  props: {
    name: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },

    review: {
      type: String,
      default: ''
    },

    backgroundImage: {
      type: String,
      default: ''
    },

  },
  computed: {
    photoStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`
      };
    }
  }
}