<script src="./IndexCarousel.js"></script>
<style src="./IndexCarousel.scss" lang="scss" scoped/>
<template>
<div class="carousel-wrapper">
  <Carousel>

    <Slide  :key="slide">
      <div class="black-overlay"></div>
      <div class="carousel-item-text ">
        <div class="carousel-item-subtitle">Browse over 200 jobs in category</div>
        <div class="carousel-item-title">Construction & Architecture</div>
        <div class="carousel-view-offers-button">
          <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'construction-architecture'}}" class="custom-router-link">
            <ButtonFlip/>
          </router-link>
        </div>
      </div>
      <div class="carousel__item" :style="`background-image: url(${require('@/assets/construction.jpeg')})`">

      </div>
    </Slide>
    <Slide  :key="slide">
      <div class="black-overlay"></div>
      <div class="carousel-item-text ">
        <div class="carousel-item-subtitle">Browse over 200 jobs in category</div>
        <div class="carousel-item-title">Engineering</div>
        <div class="carousel-view-offers-button">
          <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'engineering'}}" class="custom-router-link">
            <ButtonFlip/>
          </router-link>
        </div>
      </div>
      <div class="carousel__item" :style="`background-image: url(${require('@/assets/engineering.jpeg')})`">{{ slide }}</div>
    </Slide>
    <Slide  :key="slide">
      <div class="black-overlay"></div>
      <div class="carousel-item-text ">
        <div class="carousel-item-subtitle">Browse over 200 jobs in category</div>
        <div class="carousel-item-title">Hospitality</div>
        <div class="carousel-view-offers-button">
          <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'hospitality'}}" class="custom-router-link">
            <ButtonFlip/>
          </router-link>
        </div>
      </div>
      <div class="carousel__item" :style="`background-image: url(${require('@/assets/hospitality.jpeg')})`">{{ slide }}</div>
    </Slide>
    <Slide  :key="slide">
      <div class="black-overlay"></div>
      <div class="carousel-item-text ">
        <div class="carousel-item-subtitle">Browse over 200 jobs in category</div>
        <div class="carousel-item-title">Delivery & Transportation</div>
        <div class="carousel-view-offers-button">
          <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'delivery-transportation'}}" class="custom-router-link">
            <ButtonFlip/>
          </router-link>
        </div>
      </div>
      <div class="carousel__item" :style="`background-image: url(${require('@/assets/delivery.jpeg')})`">{{ slide }}</div>
    </Slide>

    <template #addons>
      <div class="carousel-navigation d-none d-md-block">
        <Navigation>
          <template #next>
            <span class="carousel-arrow-right">  <ArrowRightCarousel/> </span>
          </template>
          <template #prev>
            <span class="carousel-arrow-left">   <ArrowLeftCarousel/> </span>
          </template>
        </Navigation>
      </div>

      <div class="carousel-pagination">
        <Pagination />
      </div>

    </template>
  </Carousel>
</div>

</template>
