import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import OneJobApplyComponent from "@/components/Components/OneJobApplyComponent/OneJobApplyComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import Parallaxy from '@lucien144/vue3-parallaxy';
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import PinIcon from '@/components/Common/Icons/PinIcon.vue'
import BlueHeading from "@/components/Components/BlueHeading/BlueHeading.vue";
import { ref, onMounted, onUnmounted } from 'vue';


export default {
    name: "OneJobPage",
    components: {
        IndexCarousel,
        HighlightIndustries,
        HowDoesItWork,
        WhoWeAre,
        ReviewsComponent,
        OneJobApplyComponent,
        ContactForm,
        MessageIcon,
        PhoneIcon,
        PinIcon,
        Parallaxy,
        BlueHeading,


    },
    data() {
        return {
            oneJob: null,
        };
    },
    async mounted() {
        const jobId = this.$route.params.id;
        this.oneJob = await this.$store.dispatch("jobs/getSingleJob", jobId);
    }
};




