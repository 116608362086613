import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home/HomeView.vue";
import FAQ from "../views/FAQ/FAQ.vue";
import ContactsPage from "../views/ContactsPage/ContactsPage.vue";
import AboutPage from "../views/AboutPage/AboutPage.vue";
import CategoryPage from "../views/CategoryPage/CategoryPage.vue";
import OneCategoryPage from "../views/OneCategoryPage/OneCategoryPage.vue";
import OneJobPage from "../views/OneJobPage/OneJobPage.vue";
import JobApplicationPage from "../views/JobApplicationPage/JobApplicationPage.vue";

const routes = [

  {
    path: "/job/:id/application",
    name: "JobApplicationPage",
    component: JobApplicationPage,
  },

  {
    path: "/job/:id",
    name: "OneJob",
    component: OneJobPage,
  },
  {
    path: "/jobs/:slug",
    name: "OneCategoryPage",
    component: OneCategoryPage,
  },
  {
    path: "/categories",
    name: "CategoryPage",
    component: CategoryPage,
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: ContactsPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: "/new-route",
  //   name: "route-name",
  //   component: () => import(/* webpackChunkName: "about" */ "../views/Page.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
