<script src="./JobApplicationPage.js"></script>
<style src="./JobApplicationPage.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="header-wrapper" ref="headerWrapper">
      <BlueHeading :title="'Job Application'"/>
    </div>
    <div class="container">
      <div class="content-wrapper one-category-wrapper">
        <div class="application-heading">
          <div class="application-subtitle">Apply for a job offer</div>
          <div class="application-title"> {{ oneJob.title }}</div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12 input-container">
            <div class="input-placeholder">First name</div>
            <InputForm :inputtype="'text'" v-model="form.firstName"
                       :content="form.firstName" :error="formErrors.includes('firstName')"/>
          </div>
          <div class="col-md-6 col-12 input-container">
            <div class="input-placeholder">Last name</div>
            <InputForm :inputtype="'text'" v-model="form.lastName"
                       :content="form.lastName" :error="formErrors.includes('lastName')"/>
          </div>
          <div class="col-md-6 col-12 input-container">
            <div class="input-placeholder">Email</div>
            <InputForm :inputtype="'text'" :type="'text'" v-model="form.email"
                       :content="form.email" :error="formErrors.includes('email')"/>
          </div>
          <div class="col-md-6 col-12 input-container">
            <div class="input-placeholder">Phone</div>
            <InputForm :inputtype="'text'" v-model="form.phone" :content="form.phone"
                       :error="formErrors.includes('phone')"/>
          </div>
          <div v-bind:class="{'col-12 mt-4 mb-2 input-container': true, 'error-text': formErrors.includes('cvOrExperience')}">
            <div class="input-placeholder">
              Do you have CV?
            </div>
            <div class="d-flex input-placeholder">
              <div class="d-flex me-3">
                <div @click="showUploadDiv">
                  <RadioButton :checked="true"/>
                </div>
                <span class="ms-1">Yes</span>
              </div>
              <div class="d-flex">
                <div @click="showExperienceDiv">
                  <RadioButton/>
                </div>
                <span class="ms-1">No</span>
              </div>
            </div>
          </div>

          <div class="experience-wrapper" v-show="showExperience">
            <WorkExperienceComponent/>
          </div>

          <div class="col-12 input-container upload-wrapper" v-show="showUpload">
            <div class="input-placeholder">Upload CV</div>
            <InputFile v-model="form.cvFile"/>
          </div>
          <div class="col-12 input-container">
            <div class="input-placeholder">More information</div>
            <TextArea
                :label="'If you want to give us more information about yourself, you can describe it here.'"
                v-model="form.moreInfo"
                :content="form.moreInfo"
                :error="formErrors.includes('moreInfo')"
            />
          </div>
        </div>
        <div class=" col-12 button-apply">
          <ButtonMain :text="'Apply Now'" :theme="'solid-green'" @click="applyNow()"/>
        </div>
        <div class="experience-modal-wrapper" v-show="successApply">
          <div class="content-wrapper modal-content">
            <div class="d-flex">
              <div class="modal-title">Job application</div>
              <div class="ms-auto d-flex close-button" @click="successApply = false">
                <div class="me-1">
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12.6512L13.1354 1.00002" stroke="#0E2B68" stroke-width="0.66008"
                          stroke-linecap="round"/>
                    <path d="M1 1L13.1354 12.6512" stroke="#0E2B68" stroke-width="0.66008" stroke-linecap="round"/>
                  </svg>
                </div>
                <div>Close</div>
              </div>
            </div>

            <div class="success-wrapper d-flex align-items-center">
              <TickIcon :color="'green'"/>
              <div class="ms-3">You have successfully submitted your job application</div>
            </div>
            <div class="col-md-4">
              <router-link :to="{ name: 'home' }" class="custom-router-link">
                <ButtonMain :text="'Continue'" :theme="'solid-blue'"/>
              </router-link>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</template>
