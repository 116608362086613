<script src="./FAQ.js"></script>
<style src="./FAQ.scss" lang="scss" scoped/>
<template>
  <div class="header-wrapper">
    <BlueHeading :title="'Frequently Asked Questions'"/>
  </div>
  <div class="faq-wrapper">
    <div class=" faq-component content-wrapper">
      <FaqComponent/>

    </div>
  </div>


</template>
