
<template>
  <div v-bind:class="{

    'color-orange-main': color === 'orange-main',
    'color-orange-light': color === 'orange-light',
    'color-dark': color === 'dark',

  }">
    <svg width="118" height="22" viewBox="0 0 118 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0866 2.04906C10.439 1.25857 11.561 1.25857 11.9134 2.04906L13.804 6.29058C13.9494 6.61661 14.2575 6.84045 14.6124 6.87792L19.2306 7.36534C20.0913 7.45618 20.438 8.52328 19.7951 9.10267L16.3454 12.2115C16.0803 12.4505 15.9626 12.8126 16.0366 13.1618L17.0002 17.7046C17.1797 18.5512 16.272 19.2107 15.5223 18.7783L11.4996 16.4582C11.1904 16.2798 10.8096 16.2798 10.5004 16.4582L6.4777 18.7783C5.728 19.2107 4.82027 18.5512 4.99984 17.7046L5.96337 13.1618C6.03743 12.8126 5.91975 12.4505 5.65458 12.2115L2.2049 9.10267C1.56199 8.52328 1.90871 7.45618 2.7694 7.36534L7.38757 6.87792C7.74255 6.84045 8.05064 6.61661 8.19597 6.29058L10.0866 2.04906Z" fill="#FFE70D"/>
      <path d="M34.0866 2.04906C34.439 1.25857 35.561 1.25857 35.9134 2.04906L37.804 6.29058C37.9494 6.61661 38.2575 6.84045 38.6124 6.87792L43.2306 7.36534C44.0913 7.45618 44.438 8.52328 43.7951 9.10267L40.3454 12.2115C40.0803 12.4505 39.9626 12.8126 40.0366 13.1618L41.0002 17.7046C41.1797 18.5512 40.272 19.2107 39.5223 18.7783L35.4996 16.4582C35.1904 16.2798 34.8096 16.2798 34.5004 16.4582L30.4777 18.7783C29.728 19.2107 28.8203 18.5512 28.9998 17.7046L29.9634 13.1618C30.0374 12.8126 29.9197 12.4505 29.6546 12.2115L26.2049 9.10267C25.562 8.52328 25.9087 7.45618 26.7694 7.36534L31.3876 6.87792C31.7425 6.84045 32.0506 6.61661 32.196 6.29058L34.0866 2.04906Z" fill="#FFE70D"/>
      <path d="M58.0866 2.04906C58.439 1.25857 59.561 1.25857 59.9134 2.04906L61.804 6.29058C61.9494 6.61661 62.2575 6.84045 62.6124 6.87792L67.2306 7.36534C68.0913 7.45618 68.438 8.52328 67.7951 9.10267L64.3454 12.2115C64.0803 12.4505 63.9626 12.8126 64.0366 13.1618L65.0002 17.7046C65.1797 18.5512 64.272 19.2107 63.5223 18.7783L59.4996 16.4582C59.1904 16.2798 58.8096 16.2798 58.5004 16.4582L54.4777 18.7783C53.728 19.2107 52.8203 18.5512 52.9998 17.7046L53.9634 13.1618C54.0374 12.8126 53.9197 12.4505 53.6546 12.2115L50.2049 9.10267C49.562 8.52328 49.9087 7.45618 50.7694 7.36534L55.3876 6.87792C55.7425 6.84045 56.0506 6.61661 56.196 6.29058L58.0866 2.04906Z" fill="#FFE70D"/>
      <path d="M82.0866 2.04906C82.439 1.25857 83.561 1.25857 83.9134 2.04906L85.804 6.29058C85.9494 6.61661 86.2575 6.84045 86.6124 6.87792L91.2306 7.36534C92.0913 7.45618 92.438 8.52328 91.7951 9.10267L88.3454 12.2115C88.0803 12.4505 87.9626 12.8126 88.0366 13.1618L89.0002 17.7046C89.1797 18.5512 88.272 19.2107 87.5223 18.7783L83.4996 16.4582C83.1904 16.2798 82.8096 16.2798 82.5004 16.4582L78.4777 18.7783C77.728 19.2107 76.8203 18.5512 76.9998 17.7046L77.9634 13.1618C78.0374 12.8126 77.9197 12.4505 77.6546 12.2115L74.2049 9.10267C73.562 8.52328 73.9087 7.45618 74.7694 7.36534L79.3876 6.87792C79.7425 6.84045 80.0506 6.61661 80.196 6.29058L82.0866 2.04906Z" fill="#FFE70D"/>
      <path d="M106.087 2.04906C106.439 1.25857 107.561 1.25857 107.913 2.04906L109.804 6.29058C109.949 6.61661 110.257 6.84045 110.612 6.87792L115.231 7.36534C116.091 7.45618 116.438 8.52328 115.795 9.10267L112.345 12.2115C112.08 12.4505 111.963 12.8126 112.037 13.1618L113 17.7046C113.18 18.5512 112.272 19.2107 111.522 18.7783L107.5 16.4582C107.19 16.2798 106.81 16.2798 106.5 16.4582L102.478 18.7783C101.728 19.2107 100.82 18.5512 101 17.7046L101.963 13.1618C102.037 12.8126 101.92 12.4505 101.655 12.2115L98.2049 9.10267C97.562 8.52328 97.9087 7.45618 98.7694 7.36534L103.388 6.87792C103.743 6.84045 104.051 6.61661 104.196 6.29058L106.087 2.04906Z" fill="#FFE70D"/>
    </svg>



  </div>
</template>
<script>
export default {
  name: 'FiveStarsIcon',
  props: {

    color: {
      type: String,
      default: ''
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>