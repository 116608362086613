
export default {
    name: 'ButtonFlip',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        theme: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        svg: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}