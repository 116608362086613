<script src="./ContactForm.js"></script>
<style src="./ContactForm.scss" lang="scss" scoped/>
<template>

  <div v-bind:class="{
    'contact-form': true,
    'button-center': button_align === 'center'
     }" class="contact-wrapper">
      <div class="contact-title">Send message</div>
      <div class="row">
        <div class="input-field col-md-6 col-12">
          <div class="input-placeholder">Your name</div>
          <InputForm v-model="form.name" :content="form.name" :error="formErrors.includes('name')" :inputtype="'text'"/>
        </div>
        <div class="input-field col-md-6 col-12">
          <div class="input-placeholder">Your phone/email</div>
          <InputForm v-model="form.phoneOrMail" :content="form.phoneOrMail" :error="formErrors.includes('phoneOrMail')" :inputtype="'text'"/>
        </div>
        <div class="input-field col-12">
          <div class="input-placeholder">Your message</div>
          <TextArea v-model="form.message" :content="form.message" :error="formErrors.includes('message')"/>
        </div>
        <div class="d-flex button-wrapper">
          <div class="col-md-4 button-send" v-if="isSent === false">
            <ButtonMain @click="sendForm()"  :text="'Send now'" :theme="'solid-dark-blue'" />
          </div>
          <div class="alert alert-success col-md-12 mt-5" role="alert" v-if="isSent">
            Your message was sent successfully!
          </div>
        </div>

      </div>


  </div>

</template>
