import LogoBHH from '@/components/Common/Icons/LogoBHH.vue'
import LogoIntera from '@/components/Common/Icons/LogoIntera.vue'
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import ButtonMenuMobile from '@/components/Common/ButtonMenuMobile/ButtonMenuMobile.vue'


export default {
  name: "SiteHeader",
  components: {
    LogoBHH,
    LogoIntera,
    MessageIcon,
    PhoneIcon,
    ButtonMain,
    ButtonMenuMobile
  },
  data() {
    return {
      showMobileMenu: false
    };
  }


}