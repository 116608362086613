import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import FaqComponent from "@/components/Components/FaqComponent/FaqComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import Parallaxy from '@lucien144/vue3-parallaxy';
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import PinIcon from '@/components/Common/Icons/PinIcon.vue'
import BlueHeading from "@/components/Components/BlueHeading/BlueHeading.vue";
import AboutIconBox from "@/components/Components/AboutIconBox/AboutIconBox.vue";
import ButtonMain from "@/components/Common/ButtonMain/ButtonMain.vue";







export default {
  name: "AboutPage",
  components: {
    IndexCarousel,
    HighlightIndustries,
    HowDoesItWork,
    WhoWeAre,
    ReviewsComponent,
    FaqComponent,
    ContactForm,
    MessageIcon,
    PhoneIcon,
    PinIcon,
    Parallaxy,
    BlueHeading,
    AboutIconBox,
    ButtonMain

  },
  data() {
    return {
      aboutIcons: [
        {
          icon: 'HardHat',
          title: 'Blue Colour Labours',
          text: 'Not specialised but hard working labour for any company\'s needs.',
        },
        {
          icon: 'HammerWrench',
          title: 'Specialized workers',
          text: 'Workers with specific experience such as welding, machine operating in various industries, craftmanship, etc.',
        },
        {
          icon: 'Machine',
          title: 'High skilled\n' +
              'employees',
          text: 'High skilled employees - talent with education and experience in engineering.',
        },
        {
          icon: 'TaskPad',
          title: 'Proof of quality',
          text: 'We check all pro’s experience, client reviews, and identity verification.',
        },
        {
          icon: 'Shield',
          title: 'Safe and secure',
          text: 'Focus on your work knowing we help protect your data and privacy. We’re here with support if you need it.',
        },
        {
          icon: 'ArrowsSplit',
          title: 'High levels of flexibility',
          text: 'Interview potential fits for your job, negotiate salaries, and only pay for work you approve.',
        },
      ],
    }
  }
}
