<script src="./SiteFooter.js"></script>
<style src="./SiteFooter.scss" lang="scss" scoped/>
<template>
  <footer class="" >
    <nav class="navbar navbar-expand-lg d-none d-md-block">
      <div class="container-fluid">
        <div class="collapse navbar-collapse">
          <div class="me-auto">
            <router-link :to="{ name: 'home' }" class="custom-router-link">
            <LogoBHH/>
            </router-link>
          </div>
          <ul class="d-flex  mb-lg-0">

            <li class="nav-item">
              <a class="nav-link" data-replace="Privacy policy" href="#"><span>Privacy policy</span></a>
            </li>
            <router-link :to="{ name: 'about' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="About" href="#"><span>About</span></a>
              </li>
            </router-link>
            <router-link :to="{ name: 'FAQ' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="FAQ" href="#"><span>FAQ</span></a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Contacts' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="Contacts" href="#"><span>Contacts</span></a>
              </li>
            </router-link>
          </ul>
          <div class="nav-item">
            <router-link :to="{ name: 'CategoryPage' }" class="custom-router-link">
              <ButtonMain :text="'All job offers'" :theme="'solid-blue'" :size="'medium'"/>
            </router-link>
          </div>
        </div>
      </div>
    </nav>

    <div class="mobile-footer container d-md-none">
      <div class="d-flex justify-content-center">
        <div class="">
          <router-link :to="{ name: 'home' }" class="custom-router-link">
            <LogoBHH/>
          </router-link>
        </div>

      </div>
      <div class="">
        <ul class="mobile-nav ">

          <li class="nav-item">
            <a class="nav-link" data-replace="Privacy policy" href="#"><span>Privacy policy</span></a>
          </li>
          <router-link :to="{ name: 'about' }" class="custom-router-link">
            <li class="nav-item">
              <a class="nav-link" data-replace="About" href="#"><span>About</span></a>
            </li>
          </router-link>
          <router-link :to="{ name: 'FAQ' }" class="custom-router-link">
            <li class="nav-item">
              <a class="nav-link" data-replace="FAQ" href="#"><span>FAQ</span></a>
            </li>
          </router-link>
          <router-link :to="{ name: 'Contacts' }" class="custom-router-link">
            <li class="nav-item">
              <a class="nav-link" data-replace="Contacts" href="#"><span>Contacts</span></a>
            </li>
          </router-link>

        </ul>
        <div class=" d-flex justify-content-center">
          <div class="col-8">
            <router-link :to="{ name: 'CategoryPage' }" class="custom-router-link">
              <ButtonMain :text="'All job offers'" :theme="'solid-blue'" :size="'medium'"/>
            </router-link>
          </div>

        </div>
      </div>
    </div>
    <div class="footer-low d-md-flex align-items-center">
      <div>HHI.org.in 2023 © All Rights are Reserved</div>
      <div class="d-md-flex text-align-center   ms-auto">
        <a href="https://simplefinance.net/" class="logo-intera d-flex align-items-center">
          <div class="">Created by </div>
          <span> <LogoIntera/> </span>
        </a>


      </div>

    </div>


  </footer>
</template>
