import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import TickIcon from '@/components/Common/Icons/TickIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import InputForm from '@/components/Common/InputForm/InputForm.vue'
import MonthDropdown from '@/components/Common/MonthDropdown/MonthDropdown'
import YearDropdown from '@/components/Common/YearDropdown/YearDropdown'
import TextArea from '@/components/Common/TextArea/TextArea'
import CheckBox from '@/components/Common/CheckBox/CheckBox'
import ExperienceListComponent from "@/components/Components/ExperienceListComponent/ExperienceListComponent.vue";
import { defineComponent, ref } from 'vue';


export default defineComponent( {
    name: 'WorkExperienceComponent',
    components: {
        ArrowLeftCarousel,
        TickIcon,
        ButtonMain,
        InputForm,
        MonthDropdown,
        YearDropdown,
        TextArea,
        CheckBox,
        ExperienceListComponent,
    },
    data() {
        return {
            isModalVisible: false,
            successExperience: false,
            formErrors: []
        };
    },
    methods: {
        openAddModal() {
            this.$store.dispatch("utils/toggleExperienceModal");
        },
        async addExperience() {
            const editExperienceItem = this.$store.state.utils.editExpItem;

            this.validateExperienceItem(editExperienceItem);
            if (this.formErrors.length > 0) {
                return;
            }

            const item = {
                title: editExperienceItem.title,
                start_month: editExperienceItem.start_month,
                end_month: editExperienceItem.end_month,
                start_year: editExperienceItem.start_year,
                end_year: editExperienceItem.end_year,
                location: editExperienceItem.location,
                company: editExperienceItem.company,
            };

            if (this.$store.state.utils.editExperienceKey !== null) {
                await this.$store.dispatch("utils/editExperience", item);
            } else {
                await this.$store.dispatch("utils/addExperience", item);
            }

            await this.$store.dispatch("utils/toggleExperienceModal");
            this.successExperience = true;
        },
        validateExperienceItem(editExperienceItem) {
            this.formErrors = [];

            if (!editExperienceItem.title) {
                this.formErrors.push('title');
            }
            if (!editExperienceItem.title) {
                this.formErrors.push('title');
            }
            if (!editExperienceItem.company) {
                this.formErrors.push('company');
            }
            if (!editExperienceItem.location) {
                this.formErrors.push('location');
            }
            if (!editExperienceItem.start_month) {
                this.formErrors.push('start_month');
            }
            if (!editExperienceItem.start_year) {
                this.formErrors.push('start_year');
            }
            if (!editExperienceItem.end_month && editExperienceItem.currentlyWorkHere !== true) {
                this.formErrors.push('end_month');
            }
            if (!editExperienceItem.end_year && editExperienceItem.currentlyWorkHere !== true) {
                this.formErrors.push('end_year');
            }
            if (parseInt(editExperienceItem.start_year) > parseInt(editExperienceItem.end_year)) {
                this.formErrors.push('start_year');
                this.formErrors.push('end_year');
            }
            if (!editExperienceItem.description) {
                this.formErrors.push('description');
            }
        },
        closeModal() {
            this.$store.dispatch("utils/toggleExperienceModal");
        }
    },
})