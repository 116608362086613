import axios from 'axios';

export default {
    namespaced: true,
    state: {
        categories: {},
        jobsByCategory: {},
        jobs: {}, //cache by id
    },
    mutations: {
        SET_JOBS_BY_CATEGORY_LIST(state, payload) {
            if (!state.jobsByCategory.hasOwnProperty(payload.offset)) {
                state.jobsByCategory[payload.offset] = {};
            }
            state.jobsByCategory[payload.offset][payload.limit] = payload.list;
        },
        SET_JOB(state, payload) {
            if (!this.state.jobs.hasOwnProperty(payload.id)) {
                state.jobs[payload.id] = {};
            }
            state.jobs[payload.id] = payload.data;
        },
    },
    actions: {
        async applyForJob({ commit, state }, payload) {
            const id = payload.id;
            const formData = payload.formData;
            const response = await axios.post('/api/job/' + id + '/application', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return response.data;
        },
        async getCategoryBySlug({ commit, state }, slug) {
            if (state.categories.hasOwnProperty(slug)) {
                return state.categories[slug];
            }

            const response = await axios.get('/api/job/category/' + slug);
            commit('SET_CATEGORY', {slug: slug, data: response.data});
            return response.data;
        },
        async getSingleJob({ commit, state }, id) {
            if (state.jobs.hasOwnProperty(id)) {
                return state.jobs[id];
            }
            
            const response = await axios.get('/api/job/' + id);
            commit('SET_JOB', {id: id, data: response.data});
            return response.data;
        },
        async getJobsByCategory({ commit, state }, payload) {
            const categoryId = payload.slug;
            const offset = payload.offset;
            const limit = payload.limit;

            if (state.jobsByCategory.hasOwnProperty(categoryId)) {
                if (state.jobsByCategory[categoryId].hasOwnProperty(offset)) {
                    if (state.jobsByCategory[categoryId][offset].hasOwnProperty(limit)) {
                        return state.jobsByCategory[categoryId][offset][limit];
                    }
                }
            }

            let url = '/api/job?category=' + categoryId + '&offset=' + offset + '&limit=' + limit;

            const response = await axios.get(url);
            const data = response.data.data;
            commit('SET_JOBS_BY_CATEGORY_LIST', {list: data, offset: offset, limit: limit});
            return response.data;
        },
    }
};