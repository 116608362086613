import axios from 'axios';

export default {
    namespaced: true,
    state: {
        categories: [],
    },
    mutations: {
        SET_CATEGORY_LIST(state, payload) {
            state.categories = payload;
        },
    },
    actions: {
        async getCategories({ commit, state }, offset = null, limit = null) {
            if (state.categories.length > 0) {
                return state.categories;
            }
            let url = '/api/job/category';

            if (offset !== null) {
                url = url + '?offset=' + offset;
            }
            if (limit !== null) {
                url = url + '&limit=' + limit;
            }

            const response = await axios.get(url);
            commit('SET_CATEGORY_LIST', response.data);
            return response.data;
        },
    }
};