import { createStore } from 'vuex';
import category from './category';
import jobs from './jobs';
import utils from './utils';

export default createStore({
  modules: {
    category,
    jobs,
    utils,
  },
});
