<script src="./AboutIconBox.js"></script>
<style src="./AboutIconBox.scss" lang="scss" scoped/>
<template>

  <div class="icon-box-wrapper">
    <div class="svg-wrapper">
      <HardHatIcon v-if=" icon === 'HardHat'"/>
      <ShieldIcon v-if=" icon === 'Shield'"/>
      <HammerWrenchIcon v-if=" icon === 'HammerWrench'"/>
      <MachineIcon v-if=" icon === 'Machine'"/>
      <TaskPadIcon v-if=" icon === 'TaskPad'"/>
      <ArrowsSplitIcon v-if=" icon === 'ArrowsSplit'"/>
    </div>

  </div>
  <div class="icon-box-title"> {{ title }}</div>
  <div class="icon-box-text"> {{ text }}</div>

</template>
