
<template>
  <div v-bind:class="{


  }">
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM44 29L15 29V31L44 31V29Z" fill="white"/>
      <rect x="1" y="1" width="58" height="58" rx="29" stroke="white" stroke-width="2"/>
    </svg>



  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>