<script src="./CategoryPage.js"></script>
<style src="./CategoryPage.scss" lang="scss" scoped/>
<template>
  <div class="blue-heading">
    <BlueHeading :title="'All Industries'"/>
  </div>
  <div class="category-container " v-if="categoryBoxes">
    <div class="container">
      <div id="app" class="row mt-2  ">
        <div class="col-md-3 col-6 row-grid" v-for="categoryBox in categoryBoxes" :key="categoryBox.title">
          <router-link
              v-if="categoryBox.slug"
              :to="{ name: 'OneCategoryPage', params: {slug: categoryBox.slug}}"
              class="custom-router-link">
            <CategoryBox
                :imageUrl="categoryBox.image"
                :title="categoryBox.title"
                :slug="categoryBox.slug"
            />
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>
