<script src="./ContactsPage.js"></script>
<style src="./ContactsPage.scss" lang="scss" scoped/>
<template>
  <div class="blue-heading">
    <BlueHeading :title="'Contact us'"/>
  </div>
  <div class="contacts-wrapper">
    <div class="  content-wrapper">
      <div class="contacts-text d-md-flex align-self-center ">
        <div class=" contact-item">
          <div class=" d-flex justify-content-center">
            <PhoneIcon/>
            <div class="ms-1">Call us:</div>
          </div>
          <div class="text-center">{{ settings.phone }}</div>

        </div>
        <div class=" contact-item" v-if="settings">
          <div class="d-flex justify-content-center">
            <MessageIcon/>
            <div class="ms-1">Email:</div>
          </div>
          <div class="text-center">{{ settings.email }}</div>
        </div>
        <div class=" contact-item">
          <div class="d-flex justify-content-center">
            <PinIcon/>
            <div class="ms-1">Address:</div>
          </div>
          <div class="text-center">{{ settings.address }}</div>
        </div>
      </div>
      <div class="contacts-title">Send us an inquiry</div>
      <div class=" d-flex justify-content-center">
        <div class="col-md-10">
          <ContactForm :button_align="'center'"/>
        </div>

      </div>


    </div>
    <div class="flex space-x-64 d-none d-md-block">

      <Parallaxy
          :speed="160"
          direction="opposite"
          class="parallaxy-circle-solid"
      >
        <div class="blue-circle-solid-container ">
          <div class="blue-circle-solid"></div>
        </div>
      </Parallaxy>
      <Parallaxy :speed="180" direction="normal" class="parallaxy-circle-blurred">
        <div class="blue-circle-blurred-container">
          <div class="blue-circle-blurred"></div>
        </div>
      </Parallaxy>
    </div>
  </div>


</template>
