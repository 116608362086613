<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="text-area right-inner-addon">
          <textarea
              v-bind:placeholder="label"
              :value="content"
              @input="handleInput"
              v-bind:class="{'form-error': error}"
          ></textarea>
  </div>
</template>

