import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'HighlightIndustries',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    job_type: {
      type: String,
      default: ''
    },
    job_hours: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    net_gross: {
      type: String,
      default: ''
    },
    vacation_days: {
      type: String,
      default: ''
    },
    salary: {
      type: String,
      default: ''
    },

  }
}