import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import OneJobApplyComponent from "@/components/Components/OneJobApplyComponent/OneJobApplyComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import InputForm from '@/components/Common/InputForm/InputForm.vue'
import InputFile from '@/components/Common/InputFile/InputFile.vue'
import RadioButton from '@/components/Common/RadioButton/RadioButton.vue'
import TextArea from '@/components/Common/TextArea/TextArea.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import TickIcon from '@/components/Common/Icons/TickIcon.vue'
import TipTap from '@/components/Common/MonthDropdown'
import BlueHeading from "@/components/Components/BlueHeading/BlueHeading.vue";
import WorkExperienceComponent from "@/components/Components/WorkExperienceComponent/WorkExperienceComponent.vue";
import axios from 'axios';


export default {
    name: "JobApplicationPage",
    components: {
        IndexCarousel,
        HighlightIndustries,
        HowDoesItWork,
        WhoWeAre,
        ReviewsComponent,
        OneJobApplyComponent,
        ContactForm,
        ButtonMain,
        PhoneIcon,
        TickIcon,
        BlueHeading,
        TipTap,
        InputForm,
        InputFile,
        TextArea,
        RadioButton,
        WorkExperienceComponent

    },
    data() {
        return {
            form: {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                moreInfo: null,
                cvFile: null
            },
            cvOrExperience: null,
            formErrors: [],
            showUpload: true,
            showExperience: false,
            successApply: false,
            oneJob: {}
        };
    },
    created() {
        this.hasCV = 'yes'; // Set the default value to "yes"
    },
    methods: {
        showUploadDiv() {
            this.showUpload = true;
            this.showExperience = false;
        },
        showExperienceDiv() {
            this.showUpload = false;
            this.showExperience = true;
        },
        validateForm() {
            this.formErrors = [];
            if (this.form.firstName === null || this.form.firstName.length < 1) {
                this.formErrors.push('firstName')
            }
            if (this.form.lastName === null || this.form.lastName.length < 1) {
                this.formErrors.push('lastName')
            }
            if (this.form.phone === null || this.form.phone.length < 3) {
                this.formErrors.push('phone')
            }
            if (this.form.email === null || this.form.email.length < 3) {
                this.formErrors.push('email')
            }
            if (this.form.moreInfo === null || this.form.moreInfo.length < 3) {
                this.formErrors.push('moreInfo')
            }
            if (this.showExperience !== true && this.showUpload !== true) {
                this.formErrors.push('cvOrExperience')
            }

            if ((this.showExperience === true && !this.$store.state.utils.experienceList)
                || (this.showUpload === true && !this.form.cvFile)
            ) {
                this.formErrors.push('cvOrExperience')
            }
        },
        async applyNow() {

            const id = this.$route.params.id;

            this.validateForm();

            if (this.formErrors.length > 0) {
                console.log(this.formErrors);
                return
            }

            let formData = new FormData();

            formData.append("user_info", JSON.stringify(this.form));

            if (this.showUpload) {
                formData.append("file", this.form.cvFile);
            }

            if (this.showExperience) {
                formData.append("user_experience", JSON.stringify(this.$store.state.utils.experienceList));
            }

            try {
                await this.$store.dispatch("jobs/applyForJob", {id: id, formData: formData});
                this.successApply = true;
            } catch (err) {
                alert('Submitting application failed! Please try again later!');
                console.error('error', err);
            }
        }
    },

};




