import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import ButtonFlip from '@/components/Common/ButtonFlip/ButtonFlip.vue'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'IndexCarousel',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,
    ButtonFlip
  },
})