
<template>
  <div v-bind:class="{


  }">
    <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5_420)">
        <path d="M4.50477 0C6.6937 0.0140625 8.55852 1.58906 8.93431 3.75469C9.08932 4.63594 8.9437 5.48438 8.63838 6.30938C8.26259 7.32188 7.70362 8.23594 7.07888 9.10781C6.36489 10.1016 5.56166 11.0203 4.69735 11.8828C4.53765 12.0422 4.46719 12.0422 4.30748 11.8828C3.11907 10.6922 2.04339 9.4125 1.17439 7.96875C0.699965 7.17656 0.295998 6.35156 0.103409 5.44219C-0.201914 3.98906 0.140988 2.69063 1.09923 1.56563C1.80852 0.73125 2.72449 0.24375 3.80017 0.065625C4.03973 0.028125 4.2746 0.0234375 4.50477 0ZM6.56687 4.51875C6.56687 3.37969 5.64621 2.44688 4.51416 2.44688C3.36333 2.44688 2.44266 3.36094 2.43796 4.50469C2.43326 5.63438 3.36333 6.5625 4.50007 6.56719C5.62742 6.56719 6.56218 5.64375 6.56687 4.51875Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_5_420">
          <rect width="9" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>



  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>