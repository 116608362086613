import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import StepProgressBar from '@/components/Components/StepProgressBar/StepProgressBar.vue'
import Parallaxy from '@lucien144/vue3-parallaxy';


export default {
  name: 'HowDoesItWork',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,
    StepProgressBar,
    Parallaxy

  },
}