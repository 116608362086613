<script src="./AboutPage.js"></script>
<style src="./AboutPage.scss" lang="scss" scoped/>
<template>
  <div class="blue-heading">
    <BlueHeading :title="'About HHI'"/>
  </div>
  <div class="about-wrapper">

    <div class="container">
      <div class="about-title">We are new to the market but ready to rock with more than 3 decades of experience!</div>
      <div class="about-subtitle">Our network involve experience in Canada, Western Europe – Germany, France, UK, Eastern Europe – Croatia, Bulgaria and Montenegro as well as the Gulf Cost Countries – UAE, Saudi, Qatar, Kuwait.
      </div>
      <div class="about-icon-wrapper">
        <div class="row">
          <div class="col-md-2 col-6" v-for="aboutIcon in aboutIcons" :key="aboutIcon.title">
            <AboutIconBox
                :icon="aboutIcon.icon"
                :title="aboutIcon.title"
                :text="aboutIcon.text"
            />
          </div>
        </div>
      </div>
      <div class="how-does-container">
        <HowDoesItWork/>
      </div>
      <div class="content-wrapper">
        <WhoWeAre/>
      </div>
      <div class="container reviews-component">
        <ReviewsComponent/>
      </div>
      <div class="questions-wrapper">
        <div>If you have any questions we are available</div>
        <div class=" d-flex justify-content-center button-contact">
          <div class="col-md-3">
            <router-link :to="{ name: 'Contacts' }" class="custom-router-link">
              <ButtonMain :text="'Contact us'" :theme="'solid-blue'" :size="'medium'"/>
            </router-link>
          </div>

        </div>
      </div>
    </div>
    <div class="flex space-x-64 d-none d-md-block">

      <Parallaxy
          :speed="150"
          direction="opposite"
          class="parallaxy-about-small"
      >
        <div class="about-circle-small-container ">
          <div class="about-circle-small"></div>
        </div>
      </Parallaxy>
      <Parallaxy
          :speed="220"
          direction="normal"
          class="parallaxy-about-big"

      >
        <div class="about-circle-big-container">
          <div class="about-circle-big"></div>
        </div>
      </Parallaxy>
      <Parallaxy
          :speed="120"
          direction="normal"
          class="parallaxy-about-border"

      >
        <div class="about-circle-border-container">
          <div class="about-circle-border"></div>
        </div>
      </Parallaxy>
    </div>
  </div>


</template>
