
<template>
  <div v-bind:class="{


  }">
    <div v-if="color === 'black'">
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#686868"/>
        <circle cx="9" cy="2" r="2" fill="#686868"/>
        <circle cx="16" cy="2" r="2" fill="#686868"/>
      </svg>
    </div>
    <div v-if="color === 'blue'">
      <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#5E8BFF"/>
        <circle cx="9" cy="2" r="2" fill="#5E8BFF"/>
        <circle cx="16" cy="2" r="2" fill="#5E8BFF"/>
      </svg>

    </div>





  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {
    color: {
      type: String,
      default: '',
    }

  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>