import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import FaqComponent from "@/components/Components/FaqComponent/FaqComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import Parallaxy from '@lucien144/vue3-parallaxy';
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import PinIcon from '@/components/Common/Icons/PinIcon.vue'
import BlueHeading from "@/components/Components/BlueHeading/BlueHeading.vue";
import AboutIconBox from "@/components/Components/AboutIconBox/AboutIconBox.vue";
import CategoryBox from "@/components/Components/CategoryBox/CategoryBox.vue";
import ButtonMain from "@/components/Common/ButtonMain/ButtonMain.vue";







export default {
  name: "CategoryPage",
  components: {
    IndexCarousel,
    HighlightIndustries,
    HowDoesItWork,
    WhoWeAre,
    ReviewsComponent,
    FaqComponent,
    ContactForm,
    MessageIcon,
    PhoneIcon,
    PinIcon,
    Parallaxy,
    BlueHeading,
    AboutIconBox,
    CategoryBox,
    ButtonMain

  },
  data() {
    return {
      categoryBoxes: null,
    }
  },
  async mounted() {
    this.categoryBoxes = await this.$store.dispatch("category/getCategories");
  }
}
