<script src="./ReviewsComponent.js"></script>
<style src="./ReviewsComponent.scss" lang="scss" scoped/>
<template>

  <div class="reviews-wrapper">
    <div class="reviews-header">
      <div>
        <FiveStarsIcon/>
      </div>
      <div class="reviews-title">Five star service</div>
      <div class="reviews-subtitle">What users using the platform think</div>
    </div>
    <div class="row reviews-content d-none d-md-flex">
      <div class="col-md-3 col-11 single-review">
        <SingleReview
            :name="'Rajesh Patel'"
            :position="'Machine Operator'"
            :review="'Thanks to this hiring website, I landed my dream job as an operator, connecting with employers who value my skills and passion.'"
            :backgroundImage="require('@/assets/Rajesh_Patel_-_Operator.jpg')"
        />
      </div>
      <div class="col-md-3 col-11 single-review">
        <SingleReview
            :name="'Amit Sharma'"
            :position="'Mechanic'"
            :review="'Finding jobs as a mechanic was a hassle until I discovered this website - it streamlined the process and connected me with opportunities I wouldn\'t have found otherwise.'"
            :backgroundImage="require('@/assets/Amit_Sharma_-_Mechanic.jpg')"
        />
      </div>
      <div class="col-md-3 col-11 single-review">
        <SingleReview
            :name="'Arjun Singh'"
            :position="'Truck Driver'"
            :review="'As a truck driver, this hiring website made it easy to browse through a wide range of trucking jobs and choose the one that suited my preferences perfectly.'"
            :backgroundImage="require('@/assets/Arjun_Singh_-_Truck_driver.jpg')"
        />
      </div>
      <div class="col-md-3 col-11 single-review">
        <SingleReview
            :name="'Sanjay Gupta'"
            :position="'Farmer'"
            :review="'I\'m grateful for this platform that helped me explore farming positions, enabling me to continue nurturing the land while connecting with appreciative employers.'"
            :backgroundImage="require('@/assets/Sanjay_Gupta_-_Farmen.jpg')"
        />
      </div>

    </div>
    <div class="d-md-none">
      <ReviewsMobileCarousel/>
    </div>



  </div>

</template>
