<script src="./HighlightIndustries.js"></script>
<style src="./HighlightIndustries.scss" lang="scss" scoped/>
<template>

  <div>
    <div class="industry-title">Highlight industries</div>
    <div class="industry-subtitle">Most searched categories from workers</div>
    <div class="industry-box row">
      <div class="industry-buttons col-md-3 col-6">
        <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'repair-installation'}}" class="custom-router-link">
          <IndustryBox :title="'Repair and installation'" :icon="'repair'"/>
        </router-link>
      </div>
      <div class="industry-buttons col-md-3 col-6">
        <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'manufacture'}}" class="custom-router-link">
          <IndustryBox :title="'Мanufacture'" :icon="'manufacture'"/>
        </router-link>
      </div>
      <div class="industry-buttons col-md-3 col-6">
        <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'general-labour'}}" class="custom-router-link">
          <IndustryBox :title="'General labour'" :icon="'watersewage'"/>
        </router-link>
      </div>
      <div class="industry-buttons col-md-3 col-6">
        <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'other'}}" class="custom-router-link">
          <IndustryBox :title="'Other'" :icon="'factory'"/>
        </router-link>
      </div>
    </div>
    <!--
    <div class=" row">

          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Cleaning'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Construction'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Energy'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Gardening'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Household'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Agriculture'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Repairing'"/>
            </router-link>
          </div>
          <div class="industry-buttons col-md-6 col-12">
            <router-link :to="{ name: 'OneCategoryPage', params: {slug: 'category-slug-here'}}" class="custom-router-link">
              <ButtonIndustry :text="'Mechanical'"/>
            </router-link>
          </div>

    </div>
    -->
    <div class="all-industries-button">
      <router-link :to="{ name: 'CategoryPage' }" class="custom-router-link">
        <ButtonMain :text="'View all 30 industries'" :theme="'solid-red'"/>
      </router-link>
    </div>
  </div>

</template>
