<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'right-inner-addon': true,
    'input-size-large': size === 'large',
    'placeholder-left': theme === 'placeholder-left',
  }">
    <input @input="handleInput"
           :value="content"
           v-bind:placeholder="label"
           type="tel"
           v-if="inputtype === 'phone'"
           v-bind:class="{'form-error': error}"
    />
    <input @input="handleInput"
           :value="content"
           v-bind:type="inputtype"
           v-bind:placeholder="label"
           v-bind:class="{'form-error': error}"
           v-else
    />
  </div>
</template>

