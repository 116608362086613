<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="menu-toggle" ref="menuToggle" @click="toggleMenu">
    <div class="hamburger">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="cross">
      <span></span>
      <span></span>
    </div>
  </div>
</template>

