<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
  }">
    <input type="radio" id="test1" name="radio-group"  v-bind:checked="checked">
    <label for="test1">{{ label }}</label>
  </div>
</template>

