
<template>
  <div v-bind:class="{


  }">
    <div v-if="theme === 'blue'">
      <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_979_527)">
          <path d="M44 39.2564C42.5158 39.2564 41.051 39.2759 39.6059 39.2564C35.4656 39.1977 31.3253 39.0802 27.2046 38.5714C24.7439 38.2583 22.2832 37.8474 19.842 37.4755C16.6001 36.9667 13.3387 36.6536 10.0577 36.5362C9.96005 36.5362 9.8624 36.5558 9.7257 36.5558C9.7257 41.0568 9.7257 45.5186 9.7257 50.0196C6.46427 50.0196 3.26143 50.0196 0 50.0196C0 33.366 0 16.7123 0 0C0.136707 0 0.292943 0 0.449179 0C4.92144 0.136986 9.41323 0.136986 13.8855 0.450098C17.1079 0.665362 20.3302 1.23288 23.5331 1.72211C26.8531 2.23092 30.1926 2.64188 33.5517 2.73973C36.8913 2.85714 40.2503 2.87671 43.5899 2.93542C43.7071 2.93542 43.8438 2.93542 44 2.93542C44 15.0489 44 27.1233 44 39.2564ZM2.9099 2.95499C2.9099 13.1311 2.9099 23.2485 2.9099 33.4051C3.0466 33.4051 3.16378 33.4247 3.26143 33.4247C5.78074 33.4834 8.30004 33.4834 10.7998 33.6399C13.6902 33.8356 16.5806 34.1292 19.4709 34.4814C22.0879 34.7945 24.7048 35.3229 27.3218 35.636C31.755 36.184 36.2272 36.2818 40.6995 36.3405C40.8167 36.3405 40.9534 36.3209 41.0706 36.3014C41.0706 26.1448 41.0706 16.0274 41.0706 5.89041C40.9534 5.89041 40.8557 5.87084 40.7581 5.87084C39.0981 5.8317 37.4381 5.81213 35.7976 5.77299C32.2432 5.67515 28.6889 5.44031 25.1735 4.91194C22.3418 4.50098 19.5295 4.01174 16.6977 3.63992C12.7137 3.13112 8.71016 3.0137 4.68708 2.95499C4.1012 2.93542 3.53484 2.95499 2.9099 2.95499ZM6.75721 47.0646C6.75721 43.5029 6.75721 39.9804 6.75721 36.3992C5.44873 36.3992 4.17932 36.3992 2.92943 36.3992C2.92943 39.9609 2.92943 43.5225 2.92943 47.0646C4.21838 47.0646 5.48779 47.0646 6.75721 47.0646Z" fill="#00bf3d"/>
          <path d="M17.147 8.80627C20.4085 8.80627 23.6113 8.80627 26.8532 8.80627C26.8532 9.58905 26.8532 10.3718 26.8532 11.1742C29.1382 11.8983 30.9935 13.2094 32.3801 15.1664C33.7471 17.1037 34.333 19.2759 34.294 21.683C35.2704 21.683 36.2078 21.683 37.1843 21.683C37.1843 24.638 37.1843 27.5343 37.1843 30.4697C27.068 30.4697 16.9713 30.4697 6.83545 30.4697C6.83545 27.5538 6.83545 24.6575 6.83545 21.7026C7.77287 21.7026 8.71028 21.7026 9.68676 21.7026C9.80394 16.4971 12.2451 12.9941 17.1666 11.1546C17.147 10.3914 17.147 9.58905 17.147 8.80627ZM9.74535 27.5147C17.9477 27.5147 26.0916 27.5147 34.2549 27.5147C34.2549 26.5362 34.2549 25.6165 34.2549 24.6575C26.072 24.6575 17.9087 24.6575 9.74535 24.6575C9.74535 25.6165 9.74535 26.5558 9.74535 27.5147ZM20.0765 11.7417C20.0765 15.0685 20.0765 18.3562 20.0765 21.6438C21.3849 21.6438 22.6348 21.6438 23.9042 21.6438C23.9042 18.317 23.9042 15.0294 23.9042 11.7417C22.6153 11.7417 21.3654 11.7417 20.0765 11.7417ZM12.6943 21.6438C14.1785 21.6438 15.6433 21.6438 17.108 21.6438C17.108 19.2955 17.108 16.9863 17.108 14.6771C17.108 14.5793 17.0884 14.501 17.0884 14.3836C14.6668 15.2251 12.3623 18.3562 12.6943 21.6438ZM26.8923 14.3836C26.8923 16.8102 26.8923 19.2172 26.8923 21.6438C28.3765 21.6438 29.8412 21.6438 31.3059 21.6438C31.5793 18.6301 29.5092 15.2251 26.8923 14.3836Z" fill="#00bf3d"/>
        </g>
        <defs>
          <clipPath id="clip0_979_527">
            <rect width="44" height="50" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>
    <div v-if="theme === 'white'">
      <svg width="44" height="50" viewBox="0 0 44 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_979_527)">
          <path d="M44 39.2564C42.5158 39.2564 41.051 39.2759 39.6059 39.2564C35.4656 39.1977 31.3253 39.0802 27.2046 38.5714C24.7439 38.2583 22.2832 37.8474 19.842 37.4755C16.6001 36.9667 13.3387 36.6536 10.0577 36.5362C9.96005 36.5362 9.8624 36.5558 9.7257 36.5558C9.7257 41.0568 9.7257 45.5186 9.7257 50.0196C6.46427 50.0196 3.26143 50.0196 0 50.0196C0 33.366 0 16.7123 0 0C0.136707 0 0.292943 0 0.449179 0C4.92144 0.136986 9.41323 0.136986 13.8855 0.450098C17.1079 0.665362 20.3302 1.23288 23.5331 1.72211C26.8531 2.23092 30.1926 2.64188 33.5517 2.73973C36.8913 2.85714 40.2503 2.87671 43.5899 2.93542C43.7071 2.93542 43.8438 2.93542 44 2.93542C44 15.0489 44 27.1233 44 39.2564ZM2.9099 2.95499C2.9099 13.1311 2.9099 23.2485 2.9099 33.4051C3.0466 33.4051 3.16378 33.4247 3.26143 33.4247C5.78074 33.4834 8.30004 33.4834 10.7998 33.6399C13.6902 33.8356 16.5806 34.1292 19.4709 34.4814C22.0879 34.7945 24.7048 35.3229 27.3218 35.636C31.755 36.184 36.2272 36.2818 40.6995 36.3405C40.8167 36.3405 40.9534 36.3209 41.0706 36.3014C41.0706 26.1448 41.0706 16.0274 41.0706 5.89041C40.9534 5.89041 40.8557 5.87084 40.7581 5.87084C39.0981 5.8317 37.4381 5.81213 35.7976 5.77299C32.2432 5.67515 28.6889 5.44031 25.1735 4.91194C22.3418 4.50098 19.5295 4.01174 16.6977 3.63992C12.7137 3.13112 8.71016 3.0137 4.68708 2.95499C4.1012 2.93542 3.53484 2.95499 2.9099 2.95499ZM6.75721 47.0646C6.75721 43.5029 6.75721 39.9804 6.75721 36.3992C5.44873 36.3992 4.17932 36.3992 2.92943 36.3992C2.92943 39.9609 2.92943 43.5225 2.92943 47.0646C4.21838 47.0646 5.48779 47.0646 6.75721 47.0646Z" fill="#FFFFFF"/>
          <path d="M17.147 8.80627C20.4085 8.80627 23.6113 8.80627 26.8532 8.80627C26.8532 9.58905 26.8532 10.3718 26.8532 11.1742C29.1382 11.8983 30.9935 13.2094 32.3801 15.1664C33.7471 17.1037 34.333 19.2759 34.294 21.683C35.2704 21.683 36.2078 21.683 37.1843 21.683C37.1843 24.638 37.1843 27.5343 37.1843 30.4697C27.068 30.4697 16.9713 30.4697 6.83545 30.4697C6.83545 27.5538 6.83545 24.6575 6.83545 21.7026C7.77287 21.7026 8.71028 21.7026 9.68676 21.7026C9.80394 16.4971 12.2451 12.9941 17.1666 11.1546C17.147 10.3914 17.147 9.58905 17.147 8.80627ZM9.74535 27.5147C17.9477 27.5147 26.0916 27.5147 34.2549 27.5147C34.2549 26.5362 34.2549 25.6165 34.2549 24.6575C26.072 24.6575 17.9087 24.6575 9.74535 24.6575C9.74535 25.6165 9.74535 26.5558 9.74535 27.5147ZM20.0765 11.7417C20.0765 15.0685 20.0765 18.3562 20.0765 21.6438C21.3849 21.6438 22.6348 21.6438 23.9042 21.6438C23.9042 18.317 23.9042 15.0294 23.9042 11.7417C22.6153 11.7417 21.3654 11.7417 20.0765 11.7417ZM12.6943 21.6438C14.1785 21.6438 15.6433 21.6438 17.108 21.6438C17.108 19.2955 17.108 16.9863 17.108 14.6771C17.108 14.5793 17.0884 14.501 17.0884 14.3836C14.6668 15.2251 12.3623 18.3562 12.6943 21.6438ZM26.8923 14.3836C26.8923 16.8102 26.8923 19.2172 26.8923 21.6438C28.3765 21.6438 29.8412 21.6438 31.3059 21.6438C31.5793 18.6301 29.5092 15.2251 26.8923 14.3836Z" fill="#FFFFFF"/>
        </g>
        <defs>
          <clipPath id="clip0_979_527">
            <rect width="44" height="50" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>





  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {
    theme: {
      type: String,
      default: ''
    },
  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>