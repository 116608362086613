<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'right-inner-addon': true,
    'input-size-large': size === 'large',
    'placeholder-left': theme === 'placeholder-left',
  }">
    <label class="custum-file-upload" for="file">
      <div v-if="!selectedFile">
        <div class="icon">
          <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6_908)">
              <path d="M35.2158 10.3535C34.8166 10.2148 34.7091 10.0761 34.7706 9.66014C35.354 5.93169 33.4501 2.37271 30.0721 0.801213C26.648 -0.785691 22.7019 0.0770914 20.2145 2.95817C19.9228 3.29712 19.6464 3.66688 19.3854 4.00583C15.7003 2.69625 12.2609 4.32938 10.7561 7.61103C10.6026 7.59563 10.5105 7.58022 10.403 7.56481C5.47421 7.10261 1.5281 10.1532 0.944634 15.0371C0.391873 19.8749 4.15372 24.1888 8.94432 24.235C10.1266 24.2504 11.2936 24.2504 12.4759 24.2658V22.7406C11.4317 22.7406 10.3876 22.7406 9.34354 22.7406C5.76594 22.7251 2.83324 20.183 2.43402 16.7473C2.01945 13.1421 4.29191 9.93747 7.88486 9.19794C8.76007 9.01306 9.71204 9.09009 10.6026 9.18253C11.6314 9.30579 11.7695 9.29038 12.1381 8.33516C13.2282 5.54652 16.2684 4.29856 18.9862 5.5157C19.9535 5.9471 20.0763 5.90088 20.6137 4.97647C22.3334 1.97213 25.865 0.724179 29.0587 1.98754C32.2371 3.2509 33.9721 6.65581 33.1583 10.0453C32.8666 11.2162 32.9434 11.3395 34.095 11.6168C36.782 12.2485 38.5631 14.4825 38.5478 17.1787C38.5324 19.8132 36.6899 22.0318 34.0335 22.6173C33.5883 22.7097 33.1276 22.7097 32.667 22.7097C31.193 22.7097 29.7189 22.7097 28.2449 22.7097V24.235C29.8878 24.235 31.5308 24.235 33.1891 24.2196C33.4654 24.2196 33.7572 24.2042 34.0182 24.158C37.3808 23.5263 39.7761 20.9534 40.0525 17.6563C40.3442 14.4671 38.4096 11.5244 35.2158 10.3535Z" fill="black"/>
              <path d="M20.4925 32C15.7326 32 11.8633 28.1175 11.8633 23.3414C11.8633 18.5653 15.7326 14.6827 20.4925 14.6827C25.2524 14.6827 29.1217 18.5653 29.1217 23.3414C29.1371 28.1021 25.2524 32 20.4925 32ZM20.4925 16.208C16.5771 16.208 13.3987 19.3972 13.3987 23.326C13.3987 27.2547 16.5771 30.4439 20.4925 30.4439C24.4079 30.4439 27.5863 27.2547 27.5863 23.326C27.6016 19.3972 24.4079 16.208 20.4925 16.208Z" fill="black"/>
              <path d="M23.257 24.1271H17.4838C17.1306 24.1271 16.8389 23.8344 16.8389 23.48C16.8389 23.1257 17.1306 22.8329 17.4838 22.8329H23.257C23.6102 22.8329 23.9019 23.1257 23.9019 23.48C23.9019 23.8344 23.6102 24.1271 23.257 24.1271Z" fill="black"/>
              <path d="M20.3685 27.0236C20.0154 27.0236 19.7236 26.7309 19.7236 26.3765V20.5836C19.7236 20.2292 20.0154 19.9365 20.3685 19.9365C20.7217 19.9365 21.0134 20.2292 21.0134 20.5836V26.3765C20.9981 26.7463 20.7217 27.0236 20.3685 27.0236Z" fill="black"/>
            </g>
            <defs>
              <clipPath id="clip0_6_908">
                <rect width="39.2" height="32" fill="white" transform="translate(0.900391)"/>
              </clipPath>
            </defs>
          </svg>

        </div>
        <div class="text">
          <span>Drag files here or browse from your device</span>
        </div>
        <div class="button">
        <span>
          <ButtonMain :text="'Browse'" :theme="'solid-blue'" :size="'small'"/>
        </span>
        </div>
        <div class="text">
          <div>Supported file formats are: jpeg, png, doc, docx, pdf. Maximum file size is 1.5mb  </div>
        </div>
      </div>
      <div v-else>{{ selectedFile }}</div>
      <input type="file" id="file" @change="handleInput">
    </label>

  </div>
</template>

