import Manufacture from '@/components/Common/Icons/Manufacture.vue'
import FactoryIcon from '@/components/Common/Icons/FactoryIcon.vue'
import RepairIcon from '@/components/Common/Icons/RepairIcon.vue'
import WaterSewageIcon from '@/components/Common/Icons/WaterSewageIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'IndustryBox',
  components: {
    Manufacture,
    FactoryIcon,
    RepairIcon,
    WaterSewageIcon,
    ButtonMain,

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },

  }
}