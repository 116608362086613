import PinIconBlue from '@/components/Common/Icons/PinIconBlue.vue'
import BriefcaseIcon from '@/components/Common/Icons/BriefcaseIcon.vue'
import ClockIcon from '@/components/Common/Icons/ClockIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'HighlightIndustries',
  components: {
    PinIconBlue,
    BriefcaseIcon,
    ClockIcon,
    ButtonMain,

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    job_type: {
      type: String,
      default: ''
    },
    job_hours: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    net_gross: {
      type: String,
      default: ''
    },
    vacation_days: {
      type: String,
      default: ''
    },
    salary: {
      type: String,
      default: ''
    },

  }
}