<script src="./WhoWeAre.js"></script>
<style src="./WhoWeAre.scss" lang="scss" scoped/>
<template>

  <div class="who-we-are-subtitle">Who we are?</div>
  <div class="who-we-are-title">Over <span class="blue-span">30 Years</span> Experience</div>
  <p class="who-we-are-description">Hiringster Hub Private Limited is an international recruitment agency that seeks to connect exceptional talent with leading organisations. Our goal is to provide effective and efficient recruitment solutions that meet our clients' needs while helping people find meaningful employment opportunities. With a focus on professionalism and exceptional service, we aim to be the preferred choice for both job seekers and employers in the recruitment industry.
  </p>

  <div class="row">
    <div class="col-md-3 col-6 stat-box-item">
      <AboutStatisticBox :number="'10,000'" :text="'Over 10,000 employed workers through our platform'"/>
    </div>
    <div class="col-md-3 col-6 stat-box-item">
      <AboutStatisticBox :number="'1000+'" :text="'Over 1000 job offers'"/>
    </div>
    <div class="col-md-3 col-6 stat-box-item">
      <AboutStatisticBox :number="'200+'" :text="'Over 200 employers offer jobs\n'+
'on the platform'"/>
    </div>
    <div class="col-md-3 col-6 stat-box-item">
      <AboutStatisticBox :number="'20+'" :text="'You can find jobs in over 20\n'+
'industries on the platform'"/>
    </div>
  </div>

</template>
