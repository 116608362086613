import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import FiveStarsIcon from '@/components/Common/Icons/FiveStarsIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import SingleReview from "@/components/Components/SingleReview/SingleReview.vue"
import ReviewsMobileCarousel from "@/components/Components/ReviewsMobileCarousel/ReviewsMobileCarousel.vue"
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ReviewsComponent',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ArrowLeftCarousel,
    FiveStarsIcon,
    SingleReview,
    ButtonMain,
    ReviewsMobileCarousel

  },

})
