<script src="./CategoryBox.js"></script>
<style src="./CategoryBox.scss" lang="scss" scoped/>
<template>

  <div class="category-box-wrapper d-flex" :style="{'background-image': 'url(/storage/' + imageUrl + ')'}">
    <div class="blue-overlay"></div>
    <div class="black-overlay"></div>
    <router-link :to="{name: 'OneCategoryPage', params: {slug: slug}}" class="view-all-button custom-router-link">
      <div>
        <ButtonMain :text="'View all jobs'" :theme="'solid-dark-blue'"/>
      </div>
    </router-link>

    <div class="category-box-title mt-auto"> {{ title }}</div>

  </div>

</template>
