<script src="./OneJobApplyComponent.js"></script>
<style src="./OneJobApplyComponent.scss" lang="scss" scoped/>
<template>

  <div class="one-job-list-wrapper">

    <div class="">

      <div class="d-flex job-info-item">
        <PinIconBlue/>
        <div class="ms-2"> {{ location }}</div>
      </div>
      <div class="d-flex job-info-item">
        <BriefcaseIcon/>
        <div class="ms-2"> {{ job_type }}</div>
      </div>
      <div class="d-flex job-info-item">
        <ClockIcon/>
        <div class="ms-2"> {{ job_hours }}</div>
      </div>
      <div class="salary-wrapper">
        <div class="salary-title">Salary</div>
        <div class="salary-amount"> {{ salary }} {{ currency }}</div>
      </div>
      <div>
        <router-link :to="{ name: 'JobApplicationPage' }" class="custom-router-link">
          <ButtonMain :text="'Apply now'" :theme="'solid-blue'"/>
        </router-link>
      </div>
    </div>

  </div>

</template>
