<template>
  <div v-bind:class="{


  }">
    <svg width="60" height="69" viewBox="0 0 60 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_298_570)">
        <path
            d="M30.2587 0C32.0278 0.540779 33.7969 1.09846 35.5828 1.62234C43.5104 3.93755 51.4548 6.23586 59.3825 8.53417C59.7997 8.65246 59.9833 8.82146 59.9833 9.31154C59.9666 14.7024 60.0668 20.1102 59.9332 25.5011C59.783 31.6694 58.4478 37.5841 56.0278 43.2623C53.758 48.5518 50.6871 53.2836 46.765 57.4578C42.1419 62.4093 36.701 66.1609 30.5424 68.8648C30.1419 69.0338 29.8248 69.0338 29.4242 68.8648C22.7483 65.975 16.9569 61.8178 12.1502 56.3255C6.95967 50.3938 3.37135 43.5834 1.46871 35.8773C0.534075 32.0918 0.0166898 28.2557 0 24.3519C0 19.3835 0.0333797 14.4151 0 9.44673C0 8.85525 0.200278 8.65246 0.717663 8.50037C10.2809 5.72888 19.8275 2.94049 29.3741 0.152094C29.491 0.118295 29.6078 0.050698 29.7246 0C29.9082 0 30.0918 0 30.2587 0ZM4.03894 11.7957C4.02225 11.9816 4.02225 12.1168 4.02225 12.252C4.02225 16.1389 3.98887 20.0257 4.03894 23.9126C4.07232 27.5628 4.47288 31.1624 5.35744 34.7112C7.05981 41.64 10.2142 47.7913 14.8039 53.1991C18.9263 58.0492 23.8999 61.8009 29.6245 64.4879C29.8748 64.6062 30.2754 64.5555 30.5257 64.4372C34.9986 62.3248 39.0376 59.5364 42.5758 56.0382C47.4993 51.1712 51.121 45.4592 53.4409 38.9023C54.7928 35.0323 55.7107 31.0441 55.8442 26.9544C56.0111 22.155 55.9611 17.3387 55.9944 12.5224C55.9944 12.2858 55.9944 12.0323 55.9944 11.8126C47.6829 9.37913 39.4548 6.99633 31.2267 4.56282C30.3755 4.30933 29.6245 4.30933 28.7733 4.56282C20.5452 6.97943 12.3171 9.37913 4.03894 11.7957Z"
            fill="white"/>
        <path
            d="M52.1397 20.9383C51.9561 23.8281 51.9394 26.7348 51.5722 29.6077C50.5374 37.5335 47.3497 44.5298 42.1925 50.5797C38.8879 54.4835 34.9324 57.5592 30.4763 60.0096C30.1091 60.2124 29.842 60.1786 29.4916 59.9927C20.2954 54.9398 13.8532 47.4534 10.2982 37.4828C8.77945 33.2072 8.0451 28.7627 8.02841 24.2168C8.01172 21.2932 8.02841 18.3696 8.01172 15.446C8.01172 14.9897 8.12855 14.8038 8.57917 14.6686C15.5889 12.6576 22.582 10.6297 29.5917 8.60178C29.8587 8.53419 30.1758 8.53419 30.4262 8.60178C37.4359 10.6128 44.429 12.6576 51.4387 14.6686C51.8726 14.7869 51.9895 14.9559 51.9895 15.3953C51.9728 17.2373 51.9728 19.0794 51.9728 20.9214C52.0395 20.9214 52.0896 20.9214 52.1397 20.9383ZM27.9728 54.4159C27.9728 40.5922 27.9728 26.9883 27.9728 13.3505C27.8226 13.3674 27.689 13.3674 27.5555 13.4012C22.5319 14.8545 17.5082 16.3248 12.4846 17.7612C12.0507 17.8795 11.9839 18.0823 12.0006 18.471C12.0173 20.2961 11.9839 22.1044 12.0173 23.9295C12.0507 26.9883 12.3845 30.0301 13.1522 32.9875C15.0048 40.2035 18.6598 46.3211 24.1508 51.2895C25.3191 52.3542 26.6042 53.3005 27.9728 54.4159ZM32.0284 13.2998C32.0284 26.9883 32.0284 40.5922 32.0284 54.2807C32.2287 54.1455 32.3789 54.061 32.5124 53.9596C36.5347 51.1205 39.8559 47.6055 42.4429 43.3975C45.6473 38.1925 47.4999 32.5481 47.8504 26.4137C48.0006 23.8112 47.9171 21.2087 48.0006 18.5893C48.0173 18.0147 47.8003 17.8288 47.2996 17.6767C43.3942 16.5614 39.5055 15.4291 35.6167 14.2969C34.4484 13.9758 33.2468 13.6378 32.0284 13.2998Z"
            fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_298_570">
          <rect width="60" height="69" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {},

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>