<script src="./SiteHeader.js"></script>
<style src="./SiteHeader.scss" lang="scss" scoped/>
<template>
  <header>
    <div class="top-bar d-flex">
      <div class="d-flex me-5">
        <div class="me-1">
          <PhoneIcon/>
        </div>
        <div>Call us: +918813883495</div>
      </div>
      <div class="d-flex">
        <div class="me-1">
          <MessageIcon/>
        </div>
        <div>Email: info@hhi.org.in</div>
      </div>


    </div>
    <nav class="navbar navbar-expand-lg d-none d-md-block">
      <div class="container-fluid">
        <div class="collapse navbar-collapse">
          <div class="me-auto">
            <router-link :to="{ name: 'home' }" class="custom-router-link" >
              <LogoBHH/>
            </router-link>
          </div>
          <ul class="d-flex  mb-lg-0">
            <router-link :to="{ name: 'about' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="About" href="#"><span>About</span></a>
              </li>
            </router-link>
            <router-link :to="{ name: 'FAQ' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="FAQ" href="#"><span>FAQ</span></a>
              </li>
            </router-link>
            <router-link :to="{ name: 'Contacts' }" class="custom-router-link">
              <li class="nav-item">
                <a class="nav-link" data-replace="Contacts" href="#"><span>Contacts</span></a>
              </li>
            </router-link>

          </ul>
          <div class="nav-item">
            <router-link :to="{ name: 'CategoryPage' }" class="custom-router-link">
              <ButtonMain :text="'All job offers'" :theme="'solid-blue'" :size="'medium'"/>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="mobile-header d-md-none">
      <nav class="navbar">
        <div class="me-auto">
          <router-link :to="{ name: 'home' }" class="custom-router-link">
            <LogoBHH/>
          </router-link>
        </div>
        <div @click="showMobileMenu = !showMobileMenu">
          <ButtonMenuMobile/>
        </div>
      </nav>
      <div class="" v-show="showMobileMenu">
        <div class="open-mobile-menu-wrapper">
          <div class="open-mobile-menu">
            <div class="">
              <router-link :to="{ name: 'CategoryPage' }" class="custom-router-link">
                <ButtonMain :text="'All job offers'" :theme="'solid-blue'" :size="'medium'"/>
              </router-link>
            </div>
            <ul class="  ">

              <router-link :to="{ name: 'about' }" class="custom-router-link">
                <li class="nav-item">
                  <a class="nav-link" data-replace="About" href="#"><span>About</span></a>
                </li>
              </router-link>
              <router-link :to="{ name: 'FAQ' }" class="custom-router-link">
                <li class="nav-item">
                  <a class="nav-link" data-replace="FAQ" href="#"><span>FAQ</span></a>
                </li>
              </router-link>
              <router-link :to="{ name: 'Contacts' }" class="custom-router-link">
                <li class="nav-item">
                  <a class="nav-link" data-replace="Contacts" href="#"><span>Contacts</span></a>
                </li>
              </router-link>

            </ul>
          </div>
        </div>


      </div>
    </div>


  </header>
</template>
