<template>
  <div class="page-wrap">

    <SiteHeader/>

    <router-view/>

    <div class="page-footer">
      <SiteFooter/>
    </div>


  </div>



</template>

<style lang="scss">
* {
  box-sizing: border-box;
}

.page-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.page-footer {
  margin-top: auto;
  background: #ffecb3;
}
</style>
<script>
import SiteHeader from "@/components/Components/SiteHeader/SiteHeader.vue";
import SiteFooter from "@/components/Components/SiteFooter/SiteFooter.vue";

export default {
  components: {
    SiteHeader,
    SiteFooter
  }
}
</script>