<script src="./WorkExperienceComponent.js"></script>
<style src="./WorkExperienceComponent.scss" lang="scss" scoped/>
<template>

  <div class="experience-wrapper">
    <div class="experience-title">My work experience</div>
    <div class="not-required-wrapper">
      <div><span class="black-bold-span">Not required.</span> If you do not have a CV, here you can fill in information
        about your professional experience with more details
      </div>
    </div>
    <div v-for="(oneExperience, key) in $store.state.utils.experienceList" :key="oneExperience.title"
         class="experience-list">
      <ExperienceListComponent
          :item_key="key"
          :title="oneExperience.title"
          :start_month="oneExperience.start_month"
          :end_month="oneExperience.end_month"
          :start_year="oneExperience.start_year"
          :end_year="oneExperience.end_year"
          :location="oneExperience.location"
          :company="oneExperience.company"
      />
    </div>
    <div class="d-flex justify-content-center add-experience-button">
      <div class="col-md-3" @click="openAddModal">
        <ButtonMain :text="'+ Add work experience'" :theme="'outline-blue'" :size="'medium'"/>
      </div>

    </div>
  </div>
  <div class="experience-modal-wrapper" v-show="$store.state.utils.isExperienceModalOpen">
    <div class="content-wrapper modal-content">
      <div class="d-flex">
        <div class="modal-title">Adding professional experience</div>
        <div class="ms-auto d-flex close-button" @click="closeModal()">
          <div class="me-1">
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12.6512L13.1354 1.00002" stroke="#0E2B68" stroke-width="0.66008" stroke-linecap="round"/>
              <path d="M1 1L13.1354 12.6512" stroke="#0E2B68" stroke-width="0.66008" stroke-linecap="round"/>
            </svg>
          </div>
          <div>Close</div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 input-container">
          <div class="input-placeholder">Position title</div>
          <InputForm
              :inputtype="'text'"
              v-model="$store.state.utils.editExpItem.title"
              :content="$store.state.utils.editExpItem.title"
              :error="formErrors.includes('title')"
          />
        </div>
        <div class="col-md-6 col-12 input-container">
          <div class="input-placeholder">Company/Organization</div>
          <InputForm
              :inputtype="'text'"
              v-model="$store.state.utils.editExpItem.company"
              :content="$store.state.utils.editExpItem.company"
              :error="formErrors.includes('company')"
          />
        </div>
        <div class="col-md-6 col-12 input-container">
          <div class="input-placeholder">City/Location</div>
          <InputForm
              :inputtype="'text'"
              :type="'text'"
              v-model="$store.state.utils.editExpItem.location"
              :content="$store.state.utils.editExpItem.location"
              :error="formErrors.includes('location')"
          />
        </div>
      </div>
      <div class="row">
        <div class="input-placeholder">Start date</div>
        <div class="col-md-3 col-6">
          <MonthDropdown
              :placeholder="'Month'"
              v-model="$store.state.utils.editExpItem.start_month"
              :error="formErrors.includes('start_month')"
          />
        </div>
        <div class="col-md-3 col-6">
          <YearDropdown v-model="$store.state.utils.editExpItem.start_year"
                        :error="formErrors.includes('start_year')"/>
        </div>
      </div>
      <div class="row">
        <div class="input-placeholder">End date</div>
        <div class="col-md-3 col-6">
          <MonthDropdown :placeholder="'Month'"
                         v-model="$store.state.utils.editExpItem.end_month"
                         :error="formErrors.includes('start_year')"
          />
        </div>
        <div class="col-md-3 col-6">
          <YearDropdown v-model="$store.state.utils.editExpItem.end_year" :error="formErrors.includes('start_year')"/>
        </div>
        <div class="d-flex col-md-3 align-items-center mt-4 mb-3">
          <CheckBox v-model="$store.state.utils.editExpItem.currentlyWorkHere"
                    :content="$store.state.utils.editExpItem.currentlyWorkHere"/>
          <div>I currently work here</div>
        </div>
      </div>
      <div class="row">
        <div class="input-placeholder">Description</div>
        <div>
          <TextArea
              v-model="$store.state.utils.editExpItem.description"
              :content="$store.state.utils.editExpItem.description"
              :error="formErrors.includes('description')"/>
        </div>
      </div>
      <div class="col-md-6 button-add-experience">
        <ButtonMain :text="'Add experience'" :theme="'solid-blue'" @click="addExperience"/>
      </div>
    </div>

  </div>
  <div class="experience-modal-wrapper" v-show="successExperience">
    <div class="content-wrapper modal-content-success">
      <div class="d-flex">
        <div class="modal-title">Adding professional experience</div>
        <div class="ms-auto d-flex close-button" @click="closeModal()">
          <div class="me-1">
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 12.6512L13.1354 1.00002" stroke="#0E2B68" stroke-width="0.66008" stroke-linecap="round"/>
              <path d="M1 1L13.1354 12.6512" stroke="#0E2B68" stroke-width="0.66008" stroke-linecap="round"/>
            </svg>
          </div>
          <div>Close</div>
        </div>
      </div>

      <div class="success-wrapper d-flex align-items-center" v-show="successExperience">
        <TickIcon :color="'blue'"/>
        <div class="ms-3">You have successfully added work experience</div>
      </div>
      <div class="col-md-4" @click="successExperience = false">
        <ButtonMain :text="'Continue'" :theme="'solid-blue'"/>
      </div>

    </div>
  </div>

</template>
