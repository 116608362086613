import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import SingleReview from "@/components/Components/SingleReview/SingleReview.vue"
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ReviewsMobileCarousel',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,
    Carousel,
    SingleReview,
    Slide

  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1.2,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: 'start',
      },
    },
  }),
})
