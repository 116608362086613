<script src="./SingleReview.js"></script>
<style src="./SingleReview.scss" lang="scss" scoped/>
<template>

  <div class="single-review-wrapper">
    <div class="d-flex">
      <div class="reviewer-photo" :style="photoStyle"></div>
      <div class="reviewer-details">
        <div class="reviewers-name"> {{ name }}</div>
        <div class="reviewers-position"> {{ position }}</div>
      </div>
    </div>
    <div class="review-text"> {{ review }}</div>

  </div>

</template>
