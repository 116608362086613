<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'button': true,
    'button-size-super-small': size === 'super-small',
    'button-size-small': size === 'small',
    'button-size-medium': size === 'medium',
    'button-size-large': size === 'large',
    'button-theme-solid-green': theme === 'solid-green',
    'button-theme-solid-red': theme === 'solid-red',
    'button-theme-solid-white': theme === 'solid-white',
    'button-theme-solid-blue': theme === 'solid-blue',
    'button-theme-outline-blue': theme === 'outline-blue',
    'button-theme-outline-grey': theme === 'outline-grey',

  }">
    <div class=" industry-button-wrapper">
      <div class="d-flex">
        <div class="faq-question"> {{ question }} </div>
        <div class="svg-arrow ms-auto justify-content-center"
             @click="toggleFaqAnswer"
             :class="{ 'rotate-arrow': isArrowRotated }"
        >
          <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 1C9 0.447715 8.55228 -2.41411e-08 8 0C7.44772 2.41411e-08 7 0.447715 7 1L9 1ZM7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.0711 9.34315C15.4616 8.95262 15.4616 8.31946 15.0711 7.92893C14.6805 7.53841 14.0474 7.53841 13.6569 7.92893L8 13.5858L2.34315 7.92893C1.95262 7.53841 1.31946 7.53841 0.928932 7.92893C0.538408 8.31946 0.538408 8.95262 0.928933 9.34315L7.29289 15.7071ZM7 1L7 15L9 15L9 1L7 1Z" fill="white"/>
            </svg>
          </div>

        </div>
      </div>
      <transition name="fade">
        <div class="faq-answer" v-show="showFaqAnswer"> {{ answer }}</div>
      </transition>


    </div>


  </div>
</template>

