import IndexCarousel from "@/components/Components/IndexCarousel/IndexCarousel.vue";
import HighlightIndustries from "@/components/Components/HighlightIndustries/HighlightIndustries.vue";
import HowDoesItWork from "@/components/Components/HowDoesItWork/HowDoesItWork.vue";
import WhoWeAre from "@/components/Components/WhoWeAre/WhoWeAre.vue";
import ReviewsComponent from "@/components/Components/ReviewsComponent/ReviewsComponent.vue";
import FaqComponent from "@/components/Components/FaqComponent/FaqComponent.vue";
import ContactForm from "@/components/Components/ContactForm/ContactForm.vue";
import Parallaxy from '@lucien144/vue3-parallaxy';
import MessageIcon from '@/components/Common/Icons/MessageIcon.vue'
import PhoneIcon from '@/components/Common/Icons/PhoneIcon.vue'
import PinIcon from '@/components/Common/Icons/PinIcon.vue'







export default {
  name: "HomeView",
  components: {
    IndexCarousel,
    HighlightIndustries,
    HowDoesItWork,
    WhoWeAre,
    ReviewsComponent,
    FaqComponent,
    ContactForm,
    MessageIcon,
    PhoneIcon,
    PinIcon,
    Parallaxy

  },
  data() {
    return {
      settings: null
    }
  },
  async mounted() {
    this.settings = await this.$store.dispatch("utils/getConfig");
  }
};
