<script src="./FaqComponent.js"></script>
<style src="./FaqComponent.scss" lang="scss" scoped/>
<template>

  <div class="faq-header" v-if="faqHeader === 'yes'">
    <div class="faq-header-title">Frequently Asked Questions</div>
    <div class="faq-header-subtitle">Here you will find answers to the most frequently asked questions</div>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'What is a work permit visa for Europe?'" :answer="'A work permit visa for Europe is a document that allows foreign nationals to legally work in a European country for a specific period. It is granted based on the individual\'s qualifications, job offer, and the country\'s immigration policies.'"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'Do I need a work permit to work in Europe as a foreign national?'" :answer="'In many European countries, yes. The need for a work permit varies by country and your nationality. Some countries might have agreements in place that allow certain nationalities to work without a permit for a limited time.'"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="' How do I know if I\'m eligible for a work permit visa in Europe?'" :answer="'Eligibility depends on factors such as your skills, qualifications, job offer, and the country you\'re applying to. Each country has its own criteria for granting work permits, so it\'s essential to research the specific requirements of the country you\'re interested in.'"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'Can I apply for a work permit visa without a job offer?  '" :answer="'In most cases, you will need a valid job offer from an employer in the European country you wish to work in before you can apply for a work permit visa. The job offer usually needs to meet specific requirements, such as being in a high-demand occupation. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'How do I find a job in Europe that sponsors work permits?  '" :answer="'You can search for job opportunities on various online job boards, company websites, and professional networking platforms. Some countries also have shortage occupation lists that highlight jobs in demand, making it easier to find employers willing to sponsor work permits. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'What documents are typically required for a work permit visa application?  '" :answer="'Commonly required documents include a valid passport, job offer letter, police clearance certificate, educational certificates, CV/resume, proof of language proficiency (if applicable), and any additional documents required by the specific country\'s immigration authorities. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'How long does the work permit application process usually take?   '" :answer="'The processing time can vary significantly from country to country. It may take a few weeks to several months, depending on the complexity of your case and the country\'s processing times. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="' Do I need to undergo a medical examination for a work permit visa?  '" :answer="'Some countries require applicants to undergo a medical examination to ensure they meet health standards. This is especially true if your work involves public health or safety. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'Can I bring my family with me on a work permit visa?  '" :answer="'Many European countries allow work permit holders to bring their immediate family members, such as spouses and dependent children, by applying for family reunification visas. Requirements and processes can vary, so check the specific country\'s guidelines.'"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'Can I extend my work permit visa if I want to stay longer?'" :answer="'Yes, in most cases, work permit visas can be extended if you wish to continue working in the same country. The extension process typically involves demonstrating that you still meet the eligibility criteria. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'Can I apply for permanent residency after holding a work permit for a certain period?  '" :answer="'Some European countries offer a pathway to permanent residency or citizenship for individuals who have held a work permit for an extended period and have met certain criteria. However, the rules vary widely between countries. '"/>
  </div>
  <div class="button-faq">
    <ButtonFaq :question="'What happens if my work permit visa application is denied?  '" :answer="'If your application is denied, you might have the option to appeal the decision, depending on the country\'s immigration laws. It\'s crucial to understand the reasons for the denial and whether you have any avenues for reconsideration. '"/>
  </div>

</template>
