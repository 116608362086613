<script src="./AboutStatisticBox.js"></script>
<style src="./AboutStatisticBox.scss" lang="scss" scoped/>
<template>

  <div class="stats-box-wrapper">
    <div class="stats-box-number"> {{ number }}</div>
    <div class="stats-box-text"> {{ text }}</div>
  </div>

</template>
