<script src="./HomeView.js"></script>
<style src="./HomeView.scss" lang="scss" scoped />
<template>
  <div class="home-header ">
    <IndexCarousel/>
  </div>

  <div class="flex space-x-64 d-none d-md-block">

    <Parallaxy
        :speed="160"
        direction="normal"
        class="parallaxy-circle-solid"
    >
      <div class="blue-circle-solid-container ">
        <div class="blue-circle-solid"></div>
      </div>
    </Parallaxy>
    <Parallaxy
        :speed="180"
        direction="opposite"
        class="parallaxy-circle-blurred"

    >
      <div class="blue-circle-blurred-container">
        <div class="blue-circle-blurred"></div>
      </div>
    </Parallaxy>
  </div>

  <div class="highlight-industries container ">
    <div class="industry-box content-wrapper  ">
      <div class="row">
        <HighlightIndustries/>
      </div>
    </div>
  </div>
  <div>
    <HowDoesItWork/>
  </div>
  <div class="about-us-wrapper ">
    <div class="flex space-x-64 d-none d-md-block">

      <Parallaxy
          :speed="150"
          direction="opposite"
          class="parallaxy-about-small"
      >
        <div class="about-circle-small-container ">
          <div class="about-circle-small"></div>
        </div>
      </Parallaxy>
      <Parallaxy
          :speed="220"
          direction="normal"
          class="parallaxy-about-big"

      >
        <div class="about-circle-small-container">
          <div class="about-circle-big"></div>
        </div>
      </Parallaxy>
    </div>
    <div class="container">
      <div class="content-wrapper">
        <WhoWeAre/>
      </div>

      <div class="container reviews-component">
        <ReviewsComponent/>
      </div>

      <div class=" faq-component content-wrapper">
        <FaqComponent :faqHeader="'yes'"/>
      </div>

    </div>

  </div>

  <div class=" contacts-container">
    <div class="container">
      <div class="row">
        <div class="contacts-text  align-self-center col-md-5">
          <div class="contacts-title">Contact us</div>
          <div v-if="settings">
            <div class="d-flex  contact-item">
              <div class="me-1">
                <PhoneIcon/>
              </div>
              <div>
                <div>Call us: </div>
                <div>{{ settings.phone }}</div>
              </div>

            </div>
            <div class="d-flex contact-item">
              <div class="me-1">
                <MessageIcon/>
              </div>
              <div class="">
                <div>Email:</div>
                <div>{{ settings.email }}</div>
              </div>

            </div>
            <div class="d-flex contact-item">
              <div class="me-1">
                <PinIcon/>
              </div>
              <div class="">
                <div>Address:</div>
                <div>{{ settings.address }}</div>
              </div>

            </div>
          </div>
        </div>
        <div class="ms-auto col-md-7 ">
          <ContactForm/>
        </div>
      </div>

    </div>


  </div>
</template>
