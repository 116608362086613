import axios from 'axios';

export default {
    namespaced: true,
    state: {
        contactInfo: null,

        isExperienceModalOpen: false,
        editExperienceKey: null,
        editExpItemErrors: [],
        initEditExpItem: {
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            start_month: null,
            end_month: null,
            description: null,
            currentlyWorkHere: null,
        },
        editExpItem: {
            title: null,
            company: null,
            location: null,
            start_year: null,
            end_year: null,
            start_month: null,
            end_month: null,
            description: null,
            currentlyWorkHere: null,
        },
        experienceList: [
        ]
    },
    mutations: {
        SET_CONTACT_INFO(state, payload = null) {
            state.contactInfo = !state.contactInfo;
        },
        TOGGLE_EXPERIENCE_MODAL(state, payload = null) {
            if (payload !== null) {
                state.isExperienceModalOpen = payload;
                return;
            }
            state.isExperienceModalOpen = !state.isExperienceModalOpen;
        },
        ADD_TO_EXPERIENCE_LIST(state, payload) {
            state.experienceList.push(payload);
        },
        REMOVE_FROM_EXPERIENCE_LIST(state, key) {
            state.experienceList.splice(key, 1)
        },
        SET_EDIT_EXPERIENCE_KEY(state, key) {
            state.editExperienceKey = key;
            if (key !== null) {
                state.editExpItem = state.experienceList[key];
            } else {
                state.editExpItem = state.initEditExpItem;
            }
        },
        EDIT_EXPERIENCE_ITEM(state, payload) {
            state.experienceList[state.editExperienceKey] = payload;
        },
    },
    actions: {
        async toggleExperienceModal({ commit, state }, editKey = null) {
            commit('SET_EDIT_EXPERIENCE_KEY', editKey);
            commit('TOGGLE_EXPERIENCE_MODAL');
        },
        async addExperience({ commit, state }, payload) {
            commit('ADD_TO_EXPERIENCE_LIST', payload);
            commit('SET_EDIT_EXPERIENCE_KEY', null);
        },
        async editExperience({ commit, state }, payload) {
            commit('EDIT_EXPERIENCE_ITEM', payload);
            commit('SET_EDIT_EXPERIENCE_KEY', null);
        },
        async removeExperience({ commit, state }, key) {
            commit('REMOVE_FROM_EXPERIENCE_LIST', key);
        },
        async getConfig({ commit, state }, payload) {
            const response = await axios.get('/api/settings');
            commit('SET_CONTACT_INFO', response.data);
            return response.data;
        },
    }
};