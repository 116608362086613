
export default {
    name: 'ButtonMenuMobile',
    methods: {
        toggleMenu() {
            // Toggle the 'active' class on the menu-toggle element
            this.$refs.menuToggle.classList.toggle('active');
        }
    },
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        theme: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        svg: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}