
<template>
  <div>
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.2594 5.69364C4.38348 4.28246 5.49815 2.88107 6.61596 1.47641C6.61283 1.47315 6.60657 1.46661 6.60344 1.46335C6.56273 1.49928 6.52203 1.53195 6.48132 1.56788C5.63905 2.31921 4.79365 3.0738 3.95138 3.82513C3.40344 4.31512 2.85549 4.80185 2.30755 5.29185C2.26997 5.32451 2.23553 5.33105 2.18856 5.31471C1.52477 5.07625 0.857839 4.83778 0.190912 4.60259C0.0781913 4.56339 0.00617569 4.48825 -8.65411e-05 4.36412C-0.00947989 4.23999 0.0562735 4.15832 0.1596 4.09952C1.49032 3.37433 2.82418 2.64914 4.1549 1.92394C5.30089 1.30001 6.44375 0.676086 7.58974 0.052158C7.6962 -0.00664151 7.79639 -0.029508 7.89659 0.0488914C7.99678 0.124024 8.00931 0.228557 7.99052 0.352689C7.89659 0.99295 7.80892 1.63321 7.71811 2.27674C7.61792 2.98887 7.51146 3.70426 7.41126 4.41639C7.31107 5.12852 7.21087 5.84391 7.10755 6.55604C7.10128 6.6083 7.09189 6.66384 7.0825 6.7161C7.04805 6.90883 6.91029 6.99377 6.73181 6.9317C6.51576 6.85657 6.29972 6.77817 6.08367 6.69977C5.16312 6.37311 4.24258 6.04317 3.3189 5.71651C3.30637 5.71324 3.28758 5.70671 3.2594 5.69364Z" fill="white"/>
      <path d="M4.274 6.60841C4.13623 6.80441 4.00159 6.99387 3.87008 7.18661C3.70727 7.41527 3.54758 7.6472 3.38476 7.87587C3.25952 8.049 3.03408 8.04247 2.94954 7.85953C2.93388 7.8236 2.92449 7.78113 2.92449 7.74193C2.92136 7.21927 2.92449 6.69334 2.92449 6.17068C2.92449 6.16088 2.92762 6.15108 2.93075 6.13148C3.37537 6.28828 3.81999 6.44835 4.274 6.60841Z" fill="white"/>
    </svg>



  </div>
</template>
<script>

export default {
  name: 'MessageIcon',
  props: {

    
  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>