<script src="./ReviewsMobileCarousel.js"></script>
<style src="./ReviewsMobileCarousel.scss" lang="scss" scoped/>
<template>

  <div>
    <Carousel v-bind="settings" :breakpoints="breakpoints">
      <Slide  :key="slide">
        <div class="carousel__item">
          <SingleReview
              :name="'Rajesh Patel'"
              :position="'Machine Operator'"
              :review="'Thanks to this hiring website, I landed my dream job as an operator, connecting with employers who value my skills and passion.'"
              :backgroundImage="require('@/assets/Rajesh_Patel_-_Operator.jpg')"
          />
        </div>
      </Slide>
      <Slide  :key="slide">
        <div class="carousel__item">
          <SingleReview
              :name="'Amit Sharma'"
              :position="'Mechanic'"
              :review="'Finding jobs as a mechanic was a hassle until I discovered this website - it streamlined the process and connected me with opportunities I wouldn\'t have found otherwise.'"
              :backgroundImage="require('@/assets/Amit_Sharma_-_Mechanic.jpg')"
          />
        </div>
      </Slide>
      <Slide  :key="slide">
        <div class="carousel__item">
          <SingleReview
              :name="'Arjun Singh'"
              :position="'Truck Driver'"
              :review="'As a truck driver, this hiring website made it easy to browse through a wide range of trucking jobs and choose the one that suited my preferences perfectly.'"
              :backgroundImage="require('@/assets/Arjun_Singh_-_Truck_driver.jpg')"
          />
        </div>
      </Slide>
      <Slide  :key="slide">
        <div class="carousel__item">
          <SingleReview
              :name="'Sanjay Gupta'"
              :position="'Farmer'"
              :review="'I\'m grateful for this platform that helped me explore farming positions, enabling me to continue nurturing the land while connecting with appreciative employers.'"
              :backgroundImage="require('@/assets/Sanjay_Gupta_-_Farmen.jpg')"
          />
        </div>
      </Slide>



    </Carousel>
  </div>


</template>
