
<template>
  <div v-bind:class="{


  }">
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_359_1071)">
        <path d="M7.49281 3.46044C7.46816 3.47454 7.43647 3.48863 7.41535 3.50978C5.80267 5.12724 4.18999 6.74118 2.5738 8.35159C2.5245 8.40093 2.45056 8.44322 2.38013 8.46083C1.67239 8.64055 0.964641 8.81675 0.256894 8.99294C0.0878803 9.03523 -0.0353592 8.91542 0.00689437 8.74275C0.186472 8.02387 0.362528 7.30148 0.545627 6.5826C0.55619 6.53327 0.58788 6.48394 0.623092 6.4487C2.24985 4.81714 3.88013 3.18558 5.51042 1.55402C5.5245 1.53993 5.54211 1.52583 5.55267 1.51526C6.19351 2.16013 6.83436 2.80148 7.49281 3.46044Z" fill="black"/>
        <path d="M6.13037 0.96556C6.36981 0.718888 6.59868 0.447549 6.86276 0.218496C7.22192 -0.0916056 7.77122 -0.0634146 8.12685 0.257259C8.34516 0.454596 8.54938 0.662506 8.74657 0.880986C9.09164 1.26509 9.07755 1.82891 8.7184 2.20244C8.49657 2.43502 8.26417 2.65702 8.03882 2.87903C7.4015 2.23416 6.76065 1.59633 6.13037 0.96556Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_359_1071">
          <rect width="9" height="9" fill="white"/>
        </clipPath>
      </defs>
    </svg>





  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>