import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import ButtonIndustry from '@/components/Common/ButtonIndustry/ButtonIndustry.vue'
import IndustryBox from '@/components/Components/IndustryBox/IndustryBox.vue'


export default {
  name: 'HighlightIndustries',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,
    IndustryBox,
    ButtonIndustry

  },
}