<script src="./HowDoesItWork.js"></script>
<style src="./HowDoesItWork.scss" lang="scss" scoped/>
<template>

  <div class="how-does-wrapper ">
    <div class="flex space-x-64 d-none d-md-block">

      <Parallaxy
          :speed="160"
          direction="opposite"
          class="parallaxy-circle-solid"
      >
        <div class="blue-circle-solid-container ">
          <div class="blue-circle-solid"></div>
        </div>
      </Parallaxy>
      <Parallaxy
          :speed="180"
          direction="normal"
          class="parallaxy-circle-blurred"

      >
        <div class="blue-circle-blurred-container">
          <div class="blue-circle-blurred"></div>
        </div>
      </Parallaxy>
    </div>

    <div class="content-wrapper">
      <div class="how-does-title">How Does It Work?</div>
      <div class="how-does-subtitle">Check out our application process</div>
      <StepProgressBar/>
    </div>

  </div>


</template>
