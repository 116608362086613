
export default {
    name: 'RadioButton',
    props: {
        checked: {
            type: Boolean,
            default: false // Set the default value to false if not provided
        },
        label: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}