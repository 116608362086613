
<template>
  <div v-bind:class="{


  }">
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5_48)">
        <path d="M3.32106 2.19912C3.31772 2.39996 3.25083 2.51042 3.15384 2.60079C2.99665 2.74807 2.83611 2.88866 2.67223 3.0259C2.6321 3.06272 2.62541 3.0895 2.64213 3.13971C3.04347 4.21084 3.77926 4.94389 4.8428 5.35561C4.90635 5.37904 4.94648 5.369 4.98996 5.31544C5.11705 5.16481 5.24749 5.01418 5.37792 4.87025C5.54514 4.68615 5.7525 4.62925 5.98327 4.73301C6.23411 4.84682 6.48829 4.96063 6.72575 5.10456C7.07357 5.31209 7.40802 5.54305 7.74247 5.77067C7.80267 5.81084 7.85618 5.87109 7.89966 5.93134C8.02006 6.10874 8.03678 6.28615 7.90635 6.47025C7.73243 6.71795 7.52173 6.92548 7.301 7.12297C7.00334 7.39075 6.68227 7.62841 6.34782 7.85268C6.12708 7.99996 5.90969 8.02674 5.65551 7.97318C4.80267 7.79243 4.04682 7.40749 3.34447 6.9054C2.16722 6.06523 1.21404 5.02422 0.538455 3.73887C0.290963 3.27025 0.107016 2.77485 0.0167155 2.24933C-0.0133849 2.07192 -0.00669591 1.91125 0.0936385 1.75393C0.478254 1.14473 0.916381 0.582384 1.48494 0.127153C1.77926 -0.107156 2.05685 0.0166931 2.2107 0.227572C2.58862 0.743053 2.93979 1.27862 3.20735 1.86104C3.26421 1.98824 3.29431 2.12213 3.32106 2.19912Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_5_48">
          <rect width="8" height="8" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>