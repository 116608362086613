<script src="./OneJobListComponent.js"></script>
<style src="./OneJobListComponent.scss" lang="scss" scoped/>
<template>

  <div class="one-job-list-wrapper">
    <div  class="one-job-title"> {{ title }}</div>
    <div class="d-md-flex">

      <div>
        <span> {{ location }} |&nbsp; </span>
        <span> {{ job_type }}; &nbsp;</span>

      </div>

      <div>  {{ job_hours }}</div>
      <div class="ms-auto salary-amount"> {{ salary }} {{ currency }}</div>
    </div>

  </div>

</template>
