import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'BlueHeading',
  components: {
    ArrowLeftCarousel,
    ArrowRightCarousel,
    ButtonMain,

  },
  props: {
    title: {
      type: String,
      default: ''
    },

  }
}