
export default {
    name: 'CheckBox',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        theme: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    data() {
        return {
            isChecked: false,
        };
    },
    methods: {
        emitCheckboxChange(e) {
            this.$emit('update:modelValue', this.isChecked);
        },
    },
}