<script src="./ExperienceListComponent.js"></script>
<style src="./ExperienceListComponent.scss" lang="scss" scoped/>
<template>

  <div class="one-experience-wrapper d-flex">
    <div>
      <div class="date-text"> {{ start_month }} {{ start_year }} - {{ end_month }} {{ end_year }}</div>
      <div class="position-title"> {{ title }}</div>
      <div class="location-company-text"> {{ location }}, {{ company }}</div>
    </div>
    <div class="ms-auto dot-menu-icon" @click="isMenuVisible = !isMenuVisible">
      <DotMenuIcon :color="'black'" class="black-menu-icon"/>
      <DotMenuIcon :color="'blue'" class="blue-menu-icon"/>
    </div>
    <div class="edit-remove-experience-menu" v-if="isMenuVisible">
      <div class="d-flex menu-item edit-button" @click="openEditModal">
        <EditIcon/>
        <div class="ms-1">Edit</div>
      </div>
      <div class="d-flex menu-item" @click="removeItem">
        <RemoveIcon/>
        <div class="ms-1">Remove</div>
      </div>
    </div>


  </div>


</template>
