export default {
	name: 'MonthDropdown',
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		label: {
			type: String,
			default: '',
		},
		options: {
			type: Object,
		},
		selectedProp: {
			type: Number,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data() {
		return {
			years: [],
			selectedYear: null,
		};
	},
	mounted() {
		this.generateYears();
	},
	methods: {
		handleInput(e) {
			this.$emit('update:modelValue', e.target.value);
		},
		generateYears() {
			const currentYear = new Date().getFullYear();
			for (let year = currentYear; year >= 1960; year--) {
				this.years.push(year);
			}
		},
	},
};
