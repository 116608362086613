import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
export default {
	name: 'InputFile',
	components: {
		ButtonMain
	},
	props: {
		input: {
			type: String,
		},
		content: {
			type: String,
		},
		size: {
			type: String,
			default: 'medium',
		},
		theme: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		inputtype: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectedFile: false
		}
	},
	methods: {
		handleInput(e) {
			console.log((e.target.files[0]))
			this.$emit('update:modelValue', e.target.files[0]);
			const parts = e.target.value.split('\\');
			this.selectedFile = parts.at(-1);
		},
	},
};
