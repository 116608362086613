
<template>
  <div class="d-flex">
    <div class="logo-hhi">

    </div>

  </div>


</template>
<script>
export default {
  name: 'LogoBHH',
  props: {

    color: {
      type: String,
      default: ''
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.logo-hhi {
  background-image: url("~@/assets/logo-hhi.png");
  background-size: contain;
  height: 45px;
  width: 120px;
}
.hhi-text {
  font-family: $font-heavy;
  line-height: 1;
  padding-left: $spacing-1;
  padding-top: 2px;
  font-size: 17px;
}

</style>