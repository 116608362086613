import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import ArrowRightCarousel from '@/components/Common/Icons/ArrowRightCarousel.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'
import InputForm from '@/components/Common/InputForm/InputForm.vue'
import TextArea from '@/components/Common/TextArea/TextArea.vue'
import axios from "axios";


export default {
    name: 'ContactForm',
    components: {
        ArrowLeftCarousel,
        ArrowRightCarousel,
        ButtonMain,
        TextArea,
        InputForm
    },
    props: {
        button_align: {
            type: String,
            default: 'medium'
        }
    },
    data() {
        return {
            isSent: false,
            form: {
                name: null,
                phoneOrMail: null,
                message: null
            },
            formErrors: []
        }
    },
    mounted() {
    },
    methods: {
        async sendForm() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login')

            this.validateForm();
            if (this.formErrors.length > 0) {
                return;
            }

            await axios.post('/api/mail/send', {
                token: token,
                name: this.form.name,
                phoneOrMail: this.form.phoneOrMail,
                message: this.form.message,
            })

            this.form = {
                name: null,
                phoneOrMail: null,
                message: null,
            };

            this.isSent = true;
        },
        validateForm() {
            this.formErrors = [];
            if (!this.form.message) {
                this.formErrors.push('message')
            }

            if (!this.form.name) {
                this.formErrors.push('name')
            }

            if (!this.form.phoneOrMail) {
                this.formErrors.push('phoneOrMail')
            }
        }
    }
}