import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import AboutStatisticBox from '@/components/Components/AboutStatisticBox/AboutStatisticBox.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default {
  name: 'WhoWeAre',
  components: {
    ArrowLeftCarousel,
    AboutStatisticBox,
    ButtonMain,

  },
}