
export default {
    name: 'ButtonFaq',
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        answer: {
            type: String,
            default: ''
        },
        question: {
            type: String,
            default: ''
        },
        svg: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    },
    data() {
        return {
            showFaqAnswer: false,
            isArrowRotated: false,
        };
    },
    methods: {
        toggleFaqAnswer() {
            this.showFaqAnswer = !this.showFaqAnswer;
            this.isArrowRotated = !this.isArrowRotated;
        },
    },
}