<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'button': true,
    'button-size-super-small': size === 'super-small',
    'button-size-small': size === 'small',
    'button-size-medium': size === 'medium',
    'button-size-large': size === 'large',
    'button-theme-solid-green': theme === 'solid-green',
    'button-theme-solid-red': theme === 'solid-red',
    'button-theme-solid-white': theme === 'solid-white',
    'button-theme-solid-blue': theme === 'solid-blue',
    'button-theme-solid-dark-blue': theme === 'solid-dark-blue',
    'button-theme-outline-blue': theme === 'outline-blue',
    'button-theme-outline-grey': theme === 'outline-grey',

  }">
    <span class="svg-container"></span>


    {{ text }}

    <span class="svg-container">
    </span>

  </div>
</template>

