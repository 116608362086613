import ArrowLeftCarousel from '@/components/Common/Icons/ArrowLeftCarousel.vue'
import DotMenuIcon from '@/components/Common/Icons/DotMenuIcon.vue'
import EditIcon from '@/components/Common/Icons/EditIcon.vue'
import RemoveIcon from '@/components/Common/Icons/RemoveIcon.vue'
import ButtonMain from '@/components/Common/ButtonMain/ButtonMain.vue'


export default  {
  name: 'ExperienceListComponent',
  components: {
    ArrowLeftCarousel,
    DotMenuIcon,
    ButtonMain,
    EditIcon,
    RemoveIcon,
  },
  props: {
    item_key: {
      type: Number
    },
    title: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: ''
    },
    start_month: {
      type: String,
      default: ''
    },
    end_month: {
      type: String,
      default: ''
    },
    start_year: {
      type: String,
      default: ''
    },
    end_year: {
      type: String,
      default: ''
    },
    company: {
      type: String,
      default: ''
    },
    salary: {
      type: String,
      default: ''
    },
    isModalVisible: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return{
      isMenuVisible: false,
    }
  },
  methods: {
    removeItem() {
      this.$store.dispatch("utils/removeExperience", this.$props.item_key);
      this.isMenuVisible = false;
    },
    openEditModal() {
      this.$store.dispatch("utils/toggleExperienceModal", this.$props.item_key);
      this.isMenuVisible = false;
    }
  }
};