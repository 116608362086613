
<template>
  <div v-bind:class="{


  }">
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_359_1076)">
        <path d="M4.48245 5.69003C4.44723 5.73991 4.42963 5.76842 4.40498 5.79336C3.43667 6.77317 2.46484 7.75654 1.49653 8.73635C1.2078 9.02851 0.700755 9.08552 0.352164 8.86462C-0.0422027 8.61521 -0.123189 8.01663 0.183149 7.63896C0.21484 7.59977 0.250051 7.56414 0.285262 7.52851C1.25709 6.54158 2.23245 5.5582 3.2078 4.57483C3.23245 4.54989 3.26766 4.53564 3.29583 4.51782C3.2923 4.50713 3.28878 4.49288 3.28878 4.48219C3.26414 4.46438 3.23597 4.44656 3.21132 4.42519C2.24301 3.44538 1.27118 2.46557 0.302868 1.48219C0.0387833 1.21141 -0.0316393 0.844426 0.116248 0.509509C0.264135 0.178156 0.5916 -0.0249323 0.961318 0.00357122C1.16906 0.017823 1.34864 0.103334 1.49653 0.25654C2.46132 1.22566 3.42259 2.19478 4.38737 3.16747C4.41906 3.19953 4.45076 3.23873 4.48597 3.28148C4.52118 3.24941 4.54583 3.22447 4.57047 3.19953C5.52822 2.23041 6.48245 1.26129 7.44019 0.295733C7.91906 -0.185265 8.69019 -0.00711763 8.87681 0.634213C8.97188 0.954878 8.90498 1.24348 8.66906 1.48576C7.7078 2.462 6.74301 3.43469 5.77822 4.41093C5.75357 4.43588 5.72892 4.46082 5.69019 4.50357C5.72892 4.53564 5.76766 4.55702 5.79583 4.58908C6.76766 5.56533 7.73949 6.54514 8.71484 7.52139C8.90498 7.71022 9.00357 7.93469 8.99653 8.20547C8.98245 8.64371 8.61977 8.99645 8.19019 9.00713C7.88738 9.01426 7.63737 8.91093 7.41906 8.69003C6.47188 7.72091 5.51414 6.75892 4.56343 5.79692C4.5423 5.76485 4.5247 5.73991 4.48245 5.69003Z" fill="#FF3767"/>
      </g>
      <defs>
        <clipPath id="clip0_359_1076">
          <rect width="9" height="9" fill="white"/>
        </clipPath>
      </defs>
    </svg>






  </div>
</template>
<script>
export default {
  name: 'PhoneIcon',
  props: {


  },

}
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";


</style>