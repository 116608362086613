<script src="./OneCategoryPage.js"></script>
<style src="./OneCategoryPage.scss" lang="scss" scoped/>
<template>
  <div class="header-wrapper" v-if="category">
    <BlueHeading :title="category.title"/>
  </div>
  <div class="container one-category-wrapper ">
    <div class="content-wrapper  ">
      <div class="one-job-component " v-for="oneJob in oneJobList" :key="oneJob.title">
        <router-link :to="{ name: 'OneJob', params: {id: oneJob.id} }" class="custom-router-link">
          <OneJobListComponent
              :title="oneJob.title"
              :location="oneJob.location"
              :job_type="oneJob.job_type"
              :job_hours="oneJob.job_hours"
              :currency="oneJob.currency"
              :salary="oneJob.salary"
          />
        </router-link>
      </div>
      <div class="mb-5">
        <PaginationComponent
            v-if="pages && pages > 1"
            :size="'medium'"
            :pages="pages"
            :route="'OneCategoryPage'"
            :current="offset"
            @pageChanged="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>
